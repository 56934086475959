import { useCallback } from 'react';

import { useDeleteXRayElementsMutation } from '../../generated/graphql';

type Return = {
  deleteXrayElements: (elementIds: number[]) => Promise<void>;
};

export const useDeleteXrayElements = (): Return => {
  const [deleteXrayElementsMutation] = useDeleteXRayElementsMutation();

  const deleteXrayElements = useCallback(async (elementIds: number[]) => {
    await deleteXrayElementsMutation({
      variables: {
        elementIds,
      },
    });
  }, []);

  return { deleteXrayElements };
};
