import { useCallback } from 'react';

import { ElementGroupMode } from 'src/common/enums';
import { Element_Group_Modes_Enum } from 'src/communication/hasura/generated/graphql';
import { useUpdateUser } from 'src/communication/hasura/query';
import { useAppStore } from 'src/controller/store';

type Return = {
  elementGroupMode: ElementGroupMode;
  handleSetElementGroupMode(elementGroupMode: ElementGroupMode): Promise<void>;
};

export const useElementGroupMode = (): Return => {
  const { user } = useAppStore();
  const { updateUser } = useUpdateUser();

  const element_group_mode_db = user?.element_group_mode as unknown as ElementGroupMode;

  const elementGroupMode: ElementGroupMode = element_group_mode_db ?? ElementGroupMode.Tooth;

  const handleSetElementGroupMode = useCallback(
    async (elementGroupMode: ElementGroupMode) => {
      if (!user) return;
      await updateUser(user.id, {
        element_group_mode: elementGroupMode as unknown as Element_Group_Modes_Enum,
      });
    },
    [user]
  );

  return {
    elementGroupMode,
    handleSetElementGroupMode,
  };
};
