import { useCallback, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { ElectronConnect } from '../../types';
import { useIsElectronBetaTester } from '../isElectronBetaTester';
import {
  ScannerAppConfig,
  ScannerElectronMessage,
  ScannerElectronState,
  ScannerWsStatus,
  WatcherReceivedMessageType,
} from 'src/common/types';

export const useElectronConnectProvider = (): ElectronConnect => {
  const { loading, hasAccessToAllisonePlus, isAllisonePlusV2Activated } = useIsElectronBetaTester();
  const [socketUrl, setSocketUrl] = useState<string | null>(null);
  const [config, setConfig] = useState<ScannerAppConfig>();
  const [electronState, setElectronState] = useState<ScannerElectronState>();
  const [isLoading, setIsLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);

  const [wsStatus, setWsStatus] = useState<ScannerWsStatus>('');

  const handleConnectionError = () => {
    setConnectionError(true);
    setIsLoading(false);
  };

  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket<
    ScannerElectronMessage<WatcherReceivedMessageType>
  >(socketUrl, {
    onError: handleConnectionError,
    onOpen: () => setIsLoading(false),
  });

  const connect = useCallback(() => {
    if (wsStatus === 'closed' || wsStatus === '') {
      setSocketUrl('ws://127.0.0.1:4242');
    }
  }, [wsStatus]);

  const startWatcher = () => {
    if (wsStatus === 'open') sendJsonMessage({ type: 'watcher-start' });
  };

  const stopWatcher = () => {
    if (wsStatus === 'open') sendJsonMessage({ type: 'watcher-stop' });
  };

  const disconnect = () => {
    if (wsStatus === 'open') setSocketUrl(null);
  };

  const isWatcherActive = !!(config?.watcher.autorun && config.watcher.path);

  useEffect(() => {
    setWsStatus(
      {
        [ReadyState.CONNECTING]: 'connecting',
        [ReadyState.OPEN]: 'open',
        [ReadyState.CLOSING]: 'closing',
        [ReadyState.CLOSED]: 'closed',
        [ReadyState.UNINSTANTIATED]: 'uninstantiated',
      }[readyState] as ScannerWsStatus
    );
  }, [readyState]);

  useEffect(() => {
    if (isAllisonePlusV2Activated) {
      setIsLoading(false);
      return;
    }
    if (!loading && hasAccessToAllisonePlus) {
      setSocketUrl('ws://127.0.0.1:4242');
      connect();
    }
  }, [loading, hasAccessToAllisonePlus]);

  /* A hook that is called every time the lastJsonMessage changes. */
  /* it initialize and modify config state and the state of electron app */
  useEffect(() => {
    if (lastJsonMessage !== null) {
      if ((lastJsonMessage.type as any) === 'config') setConfig(lastJsonMessage.data);
      else if ((lastJsonMessage.type as any) === 'state') setElectronState(lastJsonMessage.data);
    }
  }, [lastJsonMessage]);

  return {
    isLoading,
    connectionError,
    config,
    electronState,
    wsStatus,
    lastJsonMessage,
    isWatcherActive,
    setIsLoading,
    connect,
    disconnect,
    sendJsonMessage,
    startWatcher,
    stopWatcher,
  };
};
