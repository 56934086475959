import { useCallback } from 'react';

import {
  UpdateXRayTeethAndPerioLineMutationVariables,
  useUpdateXRayTeethAndPerioLineMutation,
} from '../../generated/graphql';

type Return = {
  updateXRayTeethAndPerioLine: (
    variables: UpdateXRayTeethAndPerioLineMutationVariables
  ) => Promise<void>;
};

export const useUpdateXRayTeehtAndPerioLine = (): Return => {
  const [updateXRayTeethAndPerioLineMutation] = useUpdateXRayTeethAndPerioLineMutation();

  const updateXRayTeethAndPerioLine = useCallback(
    async (variables: UpdateXRayTeethAndPerioLineMutationVariables) => {
      const { id, detectedPeriodonticLines, teeth, centralTeeth } = variables;
      await updateXRayTeethAndPerioLineMutation({
        variables: {
          id,
          teeth,
          detectedPeriodonticLines,
          centralTeeth,
        },
      });
    },
    []
  );

  return { updateXRayTeethAndPerioLine };
};
