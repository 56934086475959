import { createContext } from 'react';

import { WaitingTreatment } from 'src/common/types';
import { CFC } from 'src/ui/components/FCWithChildren';

import { useTreatmentModalProvider } from './useTreatmentModalProvider';

export type TreatmentModalEditionType = 'create' | 'edit' | 'updateAndSave';

export type TreatmentModalValues = {
  showTreatmentModal: boolean;
  setTreatmentModalEditionType(treatmentModalEditionType: TreatmentModalEditionType): void;
  openAddTreatment(): void;
  openUpdateTreatmentPlan(): void;
  openEditTreatmentPlan(treatments: WaitingTreatment[]): void;
  openEditTreatment(treatments: WaitingTreatment[]): void;
  onCloseModal(): void;
  onSubmitModal(treatments?: WaitingTreatment[]): void;
  treatmentsToEdit: WaitingTreatment[];
};

export const TreatmentModalContext = createContext<TreatmentModalValues | null>(null);

export const TreatmentModalProvider: CFC = ({ children }) => (
  <TreatmentModalContext.Provider value={useTreatmentModalProvider()}>
    {children}
  </TreatmentModalContext.Provider>
);
