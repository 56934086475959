import * as Sentry from '@sentry/react';

import { ConsultationQuery } from 'src/common/types';
import { getAllowedRoles, getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { getDefaultRole } from 'src/controller/user/User';

import { axiosPost } from './utils/axiosUtils';

type UpdateReportsAndSuggestedTreatmentsParams = {
  consultationId: number;
  xrayIds: string[];
  hasTreatmentPreferences: boolean;
};

export const udpateReportsAndSuggestedTreatments = async ({
  consultationId,
  xrayIds,
  hasTreatmentPreferences,
}: UpdateReportsAndSuggestedTreatmentsParams): Promise<ConsultationQuery> => {
  const authTokens = getAuthTokens() ?? undefined;
  const currentRoles = getAllowedRoles();
  const role = getDefaultRole(currentRoles);

  try {
    if (!authTokens || !role) throw new Error('Token or role not found');

    const { data: updatedConsultation } = await axiosPost({
      url: `/api/v2/internal/consultation/${consultationId}/update-reports-and-suggested-treatments`,
      token: authTokens,
      role,
      data: {
        xrayIds,
        hasTreatmentPreferences,
      },
    });

    return updatedConsultation;
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        url: `/api/v2/internal/consultation/${consultationId}/update-reports-and-suggested-treatments`,
      },
    });
    throw new Error(error as any);
  }
};
