/* eslint-disable no-console */
import { from, ServerError, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from 'apollo-link-error';
import { RestLink } from 'apollo-link-rest';
import 'cross-fetch/polyfill';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { createClient } from 'graphql-ws';

import { TreatmentPreferences } from 'src/common/types/dentistry/treatmentPreferences';

import { addHeaders } from './addHeaders';
import { clearStorage } from './clearStorage';

const isServerObject = (obj: any): obj is ServerError => !!obj.result && Array.isArray(obj.result);
export const jwtExpirationMessage = 'Could not verify JWT: JWTExpired';

// HTTP Link
const httpLink = new BatchHttpLink({
  uri: '/v1/graphql',
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

// REST Link
export const fileEncode = (data: File, headers: Headers) => {
  const formData = new FormData();
  formData.append('file', data, data.name);
  headers.set('Accept', '*/*');
  return { body: formData, headers };
};

export const mlFileEncode = (
  data: {
    image: File;
    isCertified: boolean;
    treatmentPreferences?: TreatmentPreferences;
  },
  headers: Headers
) => {
  const { image, treatmentPreferences, isCertified } = data;
  const formData = new FormData();
  formData.append('file', image, image.name);
  formData.append(
    'json_treatment_preferences',
    treatmentPreferences ? JSON.stringify(treatmentPreferences) : ''
  );
  formData.append('is_certified', JSON.stringify(isCertified));
  headers.set('Accept', '*/*');
  return { body: formData, headers };
};

export const mlEditTreatmentEncode = (
  data: {
    elements: any;
    treatmentPreferences?: TreatmentPreferences;
  },
  headers: Headers
) => {
  const { elements, treatmentPreferences } = data;
  const formData = new FormData();
  formData.append('json_edition', JSON.stringify(elements));
  formData.append(
    'json_treatment_preferences',
    treatmentPreferences ? JSON.stringify(treatmentPreferences) : ''
  );
  headers.set('Accept', '*/*');
  return { body: formData, headers };
};

export const filesEncode = (files: File[], headers: Headers) => {
  const formData = new FormData();
  files.forEach((file, key) => {
    formData.append('files', file, file.name);
    formData.append('id', key.toString());
  });
  headers.set('Accept', '*/*');
  return { body: formData, headers };
};

export const authToken = (data: { code: string }, headers: Headers) => {
  const formData = new FormData();
  formData.append('grant_type', 'authorization_code');
  formData.append('code', data.code);
  return { body: formData, headers };
};

const restLink = new RestLink({
  uri: '/api',
  bodySerializers: {
    fileEncode,
    mlFileEncode,
    mlEditTreatmentEncode,
    filesEncode,
    authToken,
  },
});

export const handleErrors = ({
  graphQLErrors,
  networkError,
  response,
}: {
  graphQLErrors?: any;
  networkError?: any;
  response?: any;
}) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const { message, locations, path, extensions } = err;
      if (import.meta.env.NODE_ENV === 'development') {
        console.info(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }

      if (
        message === 'Invalid response from authorization hook' &&
        extensions?.code === 'unexpected' &&
        response
      ) {
        clearStorage();
        return;
      }

      if (extensions?.code === 'access-denied') {
        clearStorage();
        return;
      }
      if (message === jwtExpirationMessage) {
        console.log('JWT expired, should logout');
      }
    }
  }

  if (networkError) {
    if (import.meta.env.NODE_ENV === 'development') {
      console.info(`[Network error]: ${networkError}`);
    }

    if (isServerObject(networkError)) {
      const result = networkError.result as Record<string, any>;
      for (const err of result[0]?.errors) {
        const { extensions, message } = err;
        if (message === jwtExpirationMessage || networkError?.statusCode === 401) {
          console.log('JWT expired, should logout');
        }
        if (extensions?.code === 'access-denied') {
          clearStorage();
          return;
        }
      }
    }
  }
};

const errorLink: any = onError(handleErrors);

export const setContextAuth = (_: any, { headers }: any) => {
  return {
    headers: addHeaders(headers),
  };
};

const authLink = setContext(setContextAuth);

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${import.meta.env.VITE_HASURA_WS_URL}/v1/graphql`,
    connectionParams: () => ({
      headers: addHeaders(),
    }),
  })
);

// The split function takes three parameters:
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
export const splitFunction = ({ query }: { query: DocumentNode }) => {
  const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
  return kind === 'OperationDefinition' && operation === 'subscription';
};

const splitLink = split(splitFunction, wsLink, httpLink);

export const link = from([authLink, restLink, errorLink, splitLink]);
