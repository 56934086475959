import { useAuth } from 'react-oidc-context';

import { useJimo } from 'src/controller/store/user/useJimo';
import { CFC } from 'src/ui/components/FCWithChildren';

import { ConnectedLayout } from './ConnectedLayout';
import { GuestLayout } from './GuestLayout';

export const Layout: CFC = ({ children }) => {
  const auth = useAuth();
  useJimo(auth);

  return (
    <main style={{ position: 'relative' }}>
      {auth.isAuthenticated ? (
        <ConnectedLayout>{children}</ConnectedLayout>
      ) : (
        <GuestLayout>{children}</GuestLayout>
      )}
    </main>
  );
};
