import {
  UpdateXRayRetroBoneLevelMutationVariables,
  useUpdateXRayRetroBoneLevelMutation,
} from 'src/communication/hasura/generated/graphql';

type UpdateRetroBoneLevelSegments = {
  updateRetroBoneLevelSegments(items: UpdateXRayRetroBoneLevelMutationVariables): Promise<void>;
};

export const useUpdateRetroBoneLevelSegments = (): UpdateRetroBoneLevelSegments => {
  const [update] = useUpdateXRayRetroBoneLevelMutation();

  const updateRetroBoneLevelSegments = async (
    variables: UpdateXRayRetroBoneLevelMutationVariables
  ) => {
    await update({ variables });
  };

  return { updateRetroBoneLevelSegments };
};
