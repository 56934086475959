import { lazyWithRetry } from './lazyWithRetry';

export const About = lazyWithRetry(() => import('src/ui/pages/AboutPage'));
export const Account = lazyWithRetry(() => import('src/ui/pages/SettingsPage'));
export const Acquisition = lazyWithRetry(() => import('src/ui/pages/Acquisition'));
export const XrayRedirect = lazyWithRetry(() => import('src/ui/pages/XrayRedirect'));
export const Home = lazyWithRetry(() => import('src/ui/pages/Home'));
export const HomePartner = lazyWithRetry(() => import('src/ui/pages/HomePartner'));
export const Consultation = lazyWithRetry(() => import('src/ui/pages/Patient/Consultation'));
export const Invoices = lazyWithRetry(() => import('src/ui/pages/Invoices'));
export const PatientsList = lazyWithRetry(() => import('src/ui/pages/Patients'));
export const Location = lazyWithRetry(() => import('src/ui/pages/Locations/Location'));
export const PatientPage = lazyWithRetry(() => import('src/ui/pages/Patient'));
export const Locations = lazyWithRetry(() => import('src/ui/pages/Locations'));
export const Stats = lazyWithRetry(() => import('src/ui/pages/Stats'));
export const NotFound = lazyWithRetry(() => import('src/ui/pages/NotFound'));
export const Analysis = lazyWithRetry(() => import('src/ui/pages/Patient/Analysis'));
export const TreatmentPreferences = lazyWithRetry(
  () => import('src/ui/pages/TreatmentPreferences')
);
export const EducationalSheets = lazyWithRetry(() => import('src/ui/pages/EducationalSheets'));
export const Marketplace = lazyWithRetry(() => import('src/ui/pages/Marketplace'));
