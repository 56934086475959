import { Button, Icon } from '@allisone/react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useOnLogout } from 'src/controller/user/useOnLogout';

import styles from './NotFound.module.scss';

type Props = {
  message?: string;
  withHomeButton?: boolean;
  withLogoutButton?: boolean;
};

export const ErrorComponent = ({
  message,
  withHomeButton = true,
  withLogoutButton = false,
}: Props) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const { doLogout } = useOnLogout();

  const handleSubmit = () => {
    navigateTo('/');
    // force refresh the page even if we are already on root
    navigateTo(0);
  };

  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorContent}>
        <span className={styles.notFoundText}>{message || t('errorNotFound')}</span>
        {withHomeButton && (
          <Button
            testId="not-found-home"
            className={styles.homeButton}
            onClick={handleSubmit}
            label={t('home')}
          />
        )}
        {withLogoutButton && (
          <Button
            testId="not-found-logout"
            className={styles.logoutButton}
            onClick={doLogout}
            label={t('logout')}
          />
        )}
      </div>
      <div className={styles.errorImage}>
        <Icon iconName="notFound404" />
      </div>
    </div>
  );
};
