import { getDetectionBoxes } from 'src/controller/basics/math';
import { Annotation } from 'src/ui/components/XrayAnnotationTool/types';

type Props = {
  updatedAnnotations: Annotation[];
  annotations?: Annotation[];
};

export const getUpdatedAnnotations = (props: Props): Annotation[] | undefined => {
  const { updatedAnnotations, annotations } = props;

  const allAnnotations: Annotation[] = [];
  annotations?.map((annotation) => {
    // if the annotation is not in the ones being updated, then it is not modified.
    if (!updatedAnnotations.find(({ id }) => annotation.id === id)) {
      allAnnotations.push(annotation);
      return;
    }

    const updatedAnnotation = updatedAnnotations.find(({ id }) => id === annotation.id);
    if (!updatedAnnotation) return;

    // if the annotation is the one we want to update and the type is a radiolucency.
    // type.id 0 is the radiolucency type.
    if (updatedAnnotation.type.id === 0) {
      const newAnnotation = {
        ...updatedAnnotation,
        type: annotation.type,
        parentType: annotation.parentType,
        detectionBoxes: getDetectionBoxes(updatedAnnotation.path),
      };
      allAnnotations.push(newAnnotation);
      return;
    }
    // if the annotation is the one we want to update and the type is not a radiolucency
    const newAnnotation = {
      ...updatedAnnotation,
      detectionBoxes: getDetectionBoxes(updatedAnnotation.path),
    };
    allAnnotations.push(newAnnotation);
    return;
  });

  return allAnnotations;
};
