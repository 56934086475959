import { Icon } from '@allisone/react-components';

import { CFC } from 'src/ui/components/FCWithChildren';

import styles from './GuestLayout.module.scss';

export const GuestLayout: CFC = ({ children }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <Icon iconName="allisoneTitle" />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
