import { useCallback, useEffect, useReducer, useState } from 'react';

import { ScannerElectronMessage, ScannerImgData } from 'src/common/types';

import { useElectronConnect } from '../connect';
import { b64ToFile, reducer } from './utils';

export type ElectronWatcher = {
  messageHistory: ScannerElectronMessage[];
  isReady: boolean;
  disconnect: () => void;
  imgDataList?: ScannerImgData[];
  b64ToFile(b64: string, name: string, mimetype: string): File;
  refreshList: () => void;
};

export const useWatcherProvider = (): ElectronWatcher => {
  const { disconnect, lastJsonMessage, sendJsonMessage, wsStatus, electronState } =
    useElectronConnect();

  const getList = useCallback(() => {
    sendJsonMessage({ type: 'watcher-list', data: { maxNumberOfImages: 6 } });
  }, [sendJsonMessage]);

  const [imgDataList, dispatch] = useReducer(reducer, undefined);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [messageHistory, setMessageHistory] = useState<ScannerElectronMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  /* This is a useEffect hook that is triggered when the wsStatus changes.
    This should instantiate the connection to the electron app. */
  useEffect(() => {
    if (wsStatus === 'open') {
      if (!electronState) return;
      else if (!imgDataList && isLoading && electronState.watcherIsRunning) {
        getList();
        setIsLoading(false);
      }
    }
  }, [electronState, imgDataList, wsStatus, isLoading]);

  /*
  init the watcher
  */
  useEffect(() => {
    if (imgDataList && !isReady) {
      setIsReady(true);
    }
  }, [imgDataList, isReady]);

  /* Adding last json message in history and calling the messages handler */
  useEffect(() => {
    if (lastJsonMessage !== null) {
      setMessageHistory((prev) => [...prev, lastJsonMessage]);
      const watcherMsgList = ['watcher-newfile', 'watcher-list'];
      if (!watcherMsgList.includes(lastJsonMessage.type)) return;
      dispatch({
        type: lastJsonMessage.type,
        payload: lastJsonMessage.data,
      });
    }
  }, [lastJsonMessage]);

  return {
    b64ToFile,
    imgDataList,
    messageHistory,
    isReady,
    disconnect,
    refreshList: getList,
  };
};
