import { Report } from 'src/common/types';
import { ClinicalCommonFieldsFragment } from 'src/communication/hasura/generated/graphql';

import { getMissingAndDecayedTeethFromReport } from './getMissingAndDecayedTeethFromReport';

const buildUpdatedClinicalExamEntries = (
  previousReportEntries: string[],
  updatedReportEntries: string[],
  entriesFromClinical: string[]
): string[] => {
  const removedReportEntries = previousReportEntries.filter(
    (entry) => !updatedReportEntries.includes(entry)
  );
  const addedReportEntries = updatedReportEntries.filter(
    (entry) => !previousReportEntries.includes(entry)
  );

  const updatedClinicalEntriesWithDuplicates = entriesFromClinical
    .filter((entryFromClinical) => !removedReportEntries.includes(entryFromClinical))
    .concat(addedReportEntries)
    .sort((a, b) => a.localeCompare(b));

  const updatedClinicalEntries = updatedClinicalEntriesWithDuplicates.filter(
    (entry, index) => updatedClinicalEntriesWithDuplicates.indexOf(entry) === index
  );

  return updatedClinicalEntries;
};

type Return = {
  updatedDecayedTeeth: string[];
  updatedMissingTeeth: string[];
};

export const buildUpdatedClinicalExams = (
  previousReport: Report,
  updatedReport: Report,
  clinicalMonitoring: ClinicalCommonFieldsFragment
): Return => {
  const { decayedTeeth: previousReportDecays, missingTeeth: previousReportMissingTeeth } =
    getMissingAndDecayedTeethFromReport(previousReport);
  const { decayedTeeth: updatedReportDecays, missingTeeth: updatedReportMissingTeeth } =
    getMissingAndDecayedTeethFromReport(updatedReport);

  const updatedClinicalDecayedTeeth = buildUpdatedClinicalExamEntries(
    previousReportDecays,
    updatedReportDecays,
    clinicalMonitoring.decayedTeeth
  );
  const updatedClinicaMissingTeeth = buildUpdatedClinicalExamEntries(
    previousReportMissingTeeth,
    updatedReportMissingTeeth,
    clinicalMonitoring.missingTeeth
  );

  return {
    updatedDecayedTeeth: updatedClinicalDecayedTeeth,
    updatedMissingTeeth: updatedClinicaMissingTeeth,
  };
};
