import { useCallback } from 'react';

import {
  DeleteActsMutationVariables,
  GetConsultationDocument,
  useDeleteActsMutation,
} from 'src/communication/hasura/generated/graphql';

export const useDeleteActs = () => {
  const [deleteActsMutation] = useDeleteActsMutation();

  const deleteActs = useCallback(
    async (ids: DeleteActsMutationVariables['ids'], consultationId: number) => {
      await deleteActsMutation({
        variables: { ids, consultationId },
        refetchQueries: [GetConsultationDocument],
        awaitRefetchQueries: true,
      });
    },
    [deleteActsMutation]
  );

  return { deleteActs };
};
