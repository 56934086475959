import { MissingTeethOperationEnum, PathologiesEnum } from 'src/common/enums';

import {
  ExtraRules,
  Preferences,
  PriorityItem,
  TreatmentPreferences,
} from '../../../common/types/dentistry/treatmentPreferences';
import { buildInitialPreference } from './buildInitialPreference';

export const buildInitialTreatmentPreferences = (): TreatmentPreferences => {
  const pathologyValues = Object.values(PathologiesEnum);
  const missingTeethValues: string[] = Object.values(MissingTeethOperationEnum);
  const preferences: Preferences = {
    pathologies: pathologyValues.map(buildInitialPreference),
    missingTeeth: missingTeethValues.map(buildInitialPreference),
  };
  const priorities: PriorityItem[] = pathologyValues.map((item, key) => ({
    id: key,
    pathologyName: item,
  }));
  const extraRules: ExtraRules = {
    extractionIsMissingTooth: false,
    noBridgeWithHealthyPillarTeeth: false,
    noBridgeOnWisdomTeeth: false,
    noInlayOnlayOnIncisiveAndCanine: false,
    sinusLiftOnlyOnMaxilloMolar: false,
    rootCanalOnlyIfUntreatedTooth: false,
  };

  return { preferences, priorities, extraRules };
};
