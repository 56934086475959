import { OralStructureTypes_Enum } from 'src/communication/hasura/generated/graphql';

export const getOralStructureType = (nameFromMl: string): OralStructureTypes_Enum | undefined => {
  if (nameFromMl.toLowerCase() === 'ian') {
    return OralStructureTypes_Enum.InferiorAlveolarNerve;
  } else if (nameFromMl.toLowerCase() === 'sinus') {
    return OralStructureTypes_Enum.InferiorMaxillarySinus;
  }
  return undefined;
};
