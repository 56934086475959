import { useTranslation } from 'react-i18next';

import { useFormatDate } from 'src/controller/basics/useDate';
import { useGetToothNumber } from 'src/controller/dentistry/getToothNumber';

import { Clinic, ClinicForm } from '../types';
import { getConsultationReasons } from './getConsultationReasons';

type Return = {
  getCopy(formValues: ClinicForm, clinic: Clinic): string;
};

export const useCopyClinic = (): Return => {
  const formatDate = useFormatDate();
  const { t } = useTranslation('clinic');
  const { getTeethNumbers } = useGetToothNumber();

  const getFormattedClinicalExams = (clinic: Clinic): string[] =>
    Object.entries(clinic).map(
      ([name, teeth]) =>
        ` - ${t(`clinicalExams.${name}`, { ns: 'clinic' })}: ${getTeethNumbers(teeth)
          .sort((a, b) => Number(a) - Number(b))
          .join(', ')}`
    );

  const getCopy = (
    {
      initialBilanDate,
      lastConsultationDate,
      consultationComment,
      medicalBackground,
      ...reasons
    }: ClinicForm,
    clinic: Clinic
  ): string =>
    [
      t('title'),
      `${t('first.initialBilanDate')}: ${initialBilanDate ? formatDate(initialBilanDate) : ''}`,
      `${t('first.lastConsultationDate')}: ${
        lastConsultationDate ? formatDate(lastConsultationDate) : ''
      }`,
      '',
      `${t('first.title')}: ${getConsultationReasons(reasons)}`,
      `${t('comment')}: ${consultationComment}`,
      '',
      `${t('second.title')}: ${medicalBackground}`,
      '',
      `${t('third.title')}:`,
      ...getFormattedClinicalExams(clinic),
    ].join('\n');

  return { getCopy };
};
