import { useCallback } from 'react';

import { GetPatientXraysDocument, useInsertXrayElementsMutation } from '../../generated/graphql';
import { ElementFragment } from 'src/common/types';

type MappedElement = Omit<ElementFragment, 'type' | 'parentType' | 'id'> & {
  id?: number;
  typeId?: number;
  fullId?: number;
};

type Return = {
  insertXrayElements: (elements: MappedElement[]) => Promise<void>;
};

export const useInsertXrayElements = (): Return => {
  const [insertXrayElementsMutation] = useInsertXrayElementsMutation();

  const insertXrayElements = useCallback(async (elements: MappedElement[]) => {
    await insertXrayElementsMutation({
      variables: {
        elements,
      },
      refetchQueries: [GetPatientXraysDocument],
    });
  }, []);

  return { insertXrayElements };
};
