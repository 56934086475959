import { useState } from 'react';

import {
  useUpdateRetroBoneLevelSegments,
  useUpdateRetroRulerSegments,
} from 'src/communication/hasura/query';
import { RetroManager } from 'src/ui/components/XrayAnnotationTool/types';

type Return = RetroManager;

export const useRetroManager = (): Return => {
  const { updateRetroRulerSegments } = useUpdateRetroRulerSegments();
  const { updateRetroBoneLevelSegments } = useUpdateRetroBoneLevelSegments();
  // Below state exists because we can't access zoom from XRayFullscreen not in fullscreen with Carousel components (out of XRayfullScreen)
  const [shouldZoomReset, setShouldZoomReset] = useState(false);

  return {
    updateRetroBoneLevelSegments,
    updateRetroRulerSegments,
    shouldZoomReset,
    setShouldZoomReset,
  };
};
