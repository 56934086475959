import { Button, Modal } from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import styles from './PaymentStatusModal.module.scss';

type Props = {
  type: 'success' | 'failed';
};

export const PaymentStatusModal: FC<Props> = ({ type }) => {
  const navigateTo = useNavigate();
  const { t } = useTranslation('paymentStatusModal');
  const onRetry = () => navigateTo('/');
  const isSuccess = type === 'success';
  return (
    <Modal
      isOpened={true}
      icon={isSuccess ? 'infoSuccess' : 'infoError'}
      title={t(isSuccess ? 'successTitle' : 'failedTitle')}
      subtitle={t(isSuccess ? 'successSubtitle' : 'failedSubtitle')}
    >
      <div className={styles.paymentStatusModal}>
        <Button
          testId="button-payment-status"
          onClick={onRetry}
          label={t(isSuccess ? 'success.cta' : 'failed.cta')}
        />
      </div>
    </Modal>
  );
};
