import { getAllowedRoles, getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { getDefaultRole } from 'src/controller/user/User';

type Props = {
  [key: string]: string | null;
};

type AddHeaders = Props & {
  'x-hasura-role': string | null;
  authorization?: string | null;
};

export const addHeaders = (headers: Props = {}): AddHeaders => {
  const tokens = getAuthTokens();
  const allowedRoles = getAllowedRoles();
  const hasuraRole = getDefaultRole(allowedRoles);

  const authorization = tokens ? `${tokens.token_type} ${tokens.access_token}` : null;

  return {
    'x-hasura-role': String(hasuraRole),
    ...headers,
    authorization,
  };
};
