import { useToaster } from '@allisone/react-components';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';

import { AnalysisElement, ToothMl } from 'src/common/types';
import {
  registerUserEvent,
  useSendConsultation,
  useUpdateElements,
  useUpdatePatientUsersViewed,
} from 'src/communication/hasura/query';
import { useAppGetters, useAppStore } from 'src/controller/store';
import { DbPeriodonticLine, EditionMode } from 'src/ui/components/XrayAnnotationTool/types';

import { usePage } from '../Page/usePage';
import { FullScreenMode, PatientModal, SubmitEmailData, Treatment } from './TreatmentProvider';
import { useElementGroupMode } from './useElementGroupMode';

export const useTreatmentProvider = (): Treatment => {
  const { user, isClinicBetaPilot, currentConsultation, currentPatient } = useAppStore();
  const { displayedXray } = useAppGetters();
  if (!currentPatient || !currentConsultation)
    throw new Error('Consultation or patient is not defined');
  const { t } = useTranslation(['treatment', 'modalSendPdf', 'home']);
  const { setModalType } = usePage();
  const navigateTo = useNavigate();
  const { show } = useToaster();
  const { updatePatientUsersViewed } = useUpdatePatientUsersViewed();
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [fullScreenMode, setFullScreenMode] = useState<FullScreenMode>('analyzed');
  const { elementGroupMode, handleSetElementGroupMode } = useElementGroupMode();
  const [fullScreenVisible, setFullScreenVisible] = useState(!displayedXray?.isAnalysisValidated);
  const [editionMode, setEditionMode] = useState(EditionMode.VISUAL);
  const [updatedPeriodonticLine, setUpdatedPeriodonticLine] = useState<DbPeriodonticLine[]>();
  const { consultationId = '' } = useParams();
  const routerLocation = useLocation();

  // TASK-2927 - To be removed in this debt ticket
  // this is a quickfix before a MEP. periodontics is not correctly updated when switching ttt after unicity as it is badly designed
  useEffect(() => {
    if (editionMode === EditionMode.PERIODONTIC_UPDATING) setEditionMode(EditionMode.VISUAL);
  }, [displayedXray]);

  // This effect updates the user_viewed field in patients table
  useEffect(() => {
    if (!user) return;
    const { id, user: userAssigned, usersViewed } = currentPatient;
    // case where the patient is not assigned to a user
    if (!userAssigned?.id) return;
    // case where the user already view the patient file
    if (usersViewed && usersViewed.includes(user.id)) return;
    // case where the user is the first to view the patient file
    if (!usersViewed) {
      updatePatientUsersViewed(id, [user.id]);
      return;
    }
    // case where the user is not the first to view the patient file
    const newUsersViewed = [...usersViewed, user.id].filter(
      (value, index, self) => self.indexOf(value) === index
    );
    updatePatientUsersViewed(id, newUsersViewed);
  }, [user]);

  // Each time we change the treatment id, we check if the pano should be in fullscreen
  useEffect(() => {
    // check that url params match the displayed xray consultationId
    // otherwise it means the state is not updated yet
    // TODO: clean state management https://app.clickup.com/t/86bz88vx2
    const consultationIdMatch = displayedXray?.consultationId === parseInt(consultationId);

    const isInFullscreen = routerLocation.pathname.includes('/fullscreen');
    if (
      displayedXray &&
      !displayedXray?.isAnalysisValidated &&
      consultationIdMatch &&
      !isInFullscreen
    ) {
      navigateToFullscreen(displayedXray.consultationId);
    }
  }, [displayedXray?.id, consultationId]);

  const [xrayContainsChanges, setXrayContainsChanges] = useState(false);
  const updateElements = useUpdateElements();
  const submitXRayEdition = async (
    newElements: AnalysisElement[],
    teethWithNumberEdited?: ToothMl[]
  ) => {
    updateElements(currentConsultation, newElements, updatedPeriodonticLine, teethWithNumberEdited)
      .then(() => {
        show({
          text: t('treatment:xraySide.update.success'),
          type: 'success',
        });
        setXrayContainsChanges(false);
      })
      .catch((error) => {
        console.log('Error when updating elements', error);
        show({ text: t('treatment:xraySide.update.error'), type: 'error' });
      });
  };

  const sendConsultation = useSendConsultation();
  async function onSubmit(data: SubmitEmailData, sendingInProgress: { isLoading: boolean }) {
    if (!currentConsultation || !user || !currentPatient) return;
    try {
      await sendConsultation({
        locale: user.locale ?? '',
        email: data.emails,
        patientFirstname: currentPatient.firstname,
        patientLastname: currentPatient.lastname,
        doctorLastname: currentPatient.user?.lastname ?? '',
        doctorEmail: user.contactEmail ?? '', // Send empty string to disable replyTo
        observations: data.observations,
        consultation: data.treatmentPlanPDF,
      });
      sendingInProgress.isLoading = false;
      show({
        text: t('modalSendPdf:sendSuccess'),
        type: 'success',
        isOnlyOneToast: true,
      });
    } catch (err) {
      sendingInProgress.isLoading = false;
      show({
        text: t('treatment:xraySide.send.error'),
        type: 'error',
        isOnlyOneToast: true,
      });
    }
  }

  const handleFullscreen = (isFullScreen: boolean) => {
    setFullScreenVisible(isFullScreen);
    registerUserEvent('treatment.click.fullscreen', {
      consultationId: currentConsultation.id,
      patientId: currentPatient.id,
    });
  };
  const onBack = () => navigateTo('/');
  const onCloseModal = () => setModalType(null);

  const patientModal: PatientModal = {
    locationId: currentPatient.locationId ?? 0,
    consultationId: currentConsultation.id,
    patient: {
      id: currentPatient.id,
      lastname: currentPatient.lastname,
      firstname: currentPatient.firstname,
      date: parseISO(currentConsultation.consultationDate || ''),
      userId: currentPatient.user?.id ?? 0,
      birthdate: currentPatient.birthdate ? parseISO(currentPatient.birthdate) : undefined,
    },
    isOpen: showPatientModal,
    onClose: () => setShowPatientModal(false),
  };

  const navigateToTreatment = () => {
    const tab = isClinicBetaPilot ? 'clinic' : 'report';
    navigateTo(`/patient/${currentPatient.id}/${tab}/${currentConsultation.id}`);
  };
  const navigateToFullscreen = (consultationId?: number) => {
    navigateTo(
      `/patient/${currentPatient.id}/fullscreen/${consultationId ?? currentConsultation.id}`
    );
  };
  const navigateToAnnotations = () => {
    navigateTo(`/patient/${currentPatient.id}/annotations/${currentConsultation.id}`);
  };
  const navigateToNewConsultation = (newConsultationId: string) => {
    navigateTo(`/patient/${currentPatient.id}/fullscreen/${newConsultationId}`);
  };
  const navigateToBackward = () => navigateTo(-1);

  return {
    fullScreenMode,
    setFullScreenMode,
    onBack,
    setShowPatientModal,
    setFullScreenVisible: handleFullscreen,
    isOpen: showPatientModal,
    fullScreenVisible,
    submitXRayEdition,
    onSubmit,
    patientModal,
    editionMode,
    setEditionMode,
    navigateTo: {
      fullscreen: navigateToFullscreen,
      backward: navigateToBackward,
      treatment: navigateToTreatment,
      annotations: navigateToAnnotations,
      toNewTreatment: navigateToNewConsultation,
    },
    xrayContainsChanges,
    setXrayContainsChanges,
    updatedPeriodonticLine,
    setUpdatedPeriodonticLine,
    onCloseModal,
    elementGroupMode,
    handleSetElementGroupMode,
  };
};
