import { Corner } from 'src/common/types';

export const tempMapTeethCorner = (corner: Corner | undefined | null): number | null => {
  if (!corner) return null;
  if (corner === 'upper_left') return 1;
  if (corner === 'upper_right') return 2;
  if (corner === 'lower_right') return 3;
  if (corner === 'lower_left') return 4;
  return corner;
};
