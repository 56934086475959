import { Button, Icon } from '@allisone/react-components';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { clearStorage } from 'src/communication/apollo/clearStorage';
import { resetAllStore } from 'src/controller/store/resetAllStore';

import styles from './StripeModal.module.scss';

type Props = {
  paymasters?: string[];
};

export const BenificiaryContent: FC<Props> = ({ paymasters }) => {
  const { t } = useTranslation(['centrePaymentModal', 'layout']);
  const contact = paymasters?.join(', ');
  const auth = useAuth();

  const onLogout = () => {
    clearStorage();
    resetAllStore();
    auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    });
  };

  return (
    <div className={styles.beneficiaryContent}>
      <Icon iconName="licenceBlocked" className={styles.icon} />
      <p className={styles.title}>{t('beneficiariesTitle')}</p>
      <p className={styles.subtitle}>
        <Trans t={t} i18nKey="beneficiariesSubtitle">
          text <span>{{ contact }}</span> text
        </Trans>
      </p>
      <div className={styles.logoutButton}>
        <Button testId="button-logout" label={t('layout:header.logout')} onClick={onLogout} />
      </div>
    </div>
  );
};
