import { debounce } from 'lodash';
import { createContext, useContext } from 'react';

import { useOnLogout } from '../../user/useOnLogout';
import { useRecentPatients } from './useRecentPatients';
import { useRecentXrays } from './useRecentXrays';
import { RecentPatient } from 'src/communication/api/recentPatients';
import { RecentXray } from 'src/communication/api/recentXrays';
import { useAppStore } from 'src/controller/store';
import { CFC } from 'src/ui/components/FCWithChildren';

export type RecentDataContextValues = {
  recentPatients: RecentPatient[];
  recentXrays: RecentXray[];
};

const RecentDataContext = createContext<RecentDataContextValues | null>(null);

type RecentDataProviderProps = {
  withRecentPatients?: boolean;
  withRecentXrays?: boolean;
};

export const RecentDataProvider: CFC<RecentDataProviderProps> = ({
  children,
  withRecentPatients = false,
  withRecentXrays = false,
}) => {
  const { currentLocation } = useAppStore();
  const { shouldLogout } = useOnLogout();

  const onError = debounce((error: any) => {
    // Debounce to avoid multiple logout calls from recentPatients and recentXrays at the same time
    shouldLogout(error);
  }, 500);

  const patientsValues = withRecentPatients
    ? useRecentPatients(currentLocation, onError)
    : { recentPatients: [] };
  const xraysValues = withRecentXrays
    ? useRecentXrays(currentLocation, onError)
    : { recentXrays: [] };

  return (
    <RecentDataContext.Provider value={{ ...xraysValues, ...patientsValues }}>
      {children}
    </RecentDataContext.Provider>
  );
};

export const useRecentData = (): RecentDataContextValues => {
  const values = useContext(RecentDataContext);
  if (!values) throw new Error(`useRecentData can only be used in children of RecentDataContext`);
  return values;
};
