import { ToothNumberingSystem } from 'src/common/enums';
import { useAppStore } from 'src/controller/store';

type Return = {
  getToothNumber: (toothNumber: string) => string;
  getTeethNumbers: (toothNumber: string[]) => string[];
};

export const useGetToothNumber = (): Return => {
  const { user } = useAppStore();
  const version = user?.toothNumberingSystem as ToothNumberingSystem;

  const getToothNumber = (toothNumber: string): string => {
    if (!version) return toothNumber;
    return getToothNumberWithVersion(toothNumber, version);
  };

  const getTeethNumbers = (toomNumbers: string[]): string[] => {
    if (!version) return toomNumbers;
    return toomNumbers.map((toothNumber) => getToothNumber(toothNumber));
  };

  return { getToothNumber, getTeethNumbers };
};

export const getToothNumberWithVersion = (
  toothNumber: string,
  version: ToothNumberingSystem
): string =>
  version === ToothNumberingSystem.UNS ? convertToothNumberFDItoUNS(toothNumber) : toothNumber;

export const convertToothNumberFDItoUNS = (toothNumber: string): string =>
  toothNumberingConversionTable[toothNumber] ?? toothNumber;

export const convertToothNumberUNStoFDI = (toothNumber: string): string =>
  reverseToothNumberingConversionTable[toothNumber] ?? toothNumber;

export const sortTeeth = (teeth: string[]) => {
  return teeth
    .map(convertToothNumberFDItoUNS)
    .sort((t1, t2) => parseInt(t1, 10) - parseInt(t2, 10))
    .map(convertToothNumberUNStoFDI);
};

const toothNumberingConversionTable: { [key: string]: string } = {
  '18': '1',
  '17': '2',
  '16': '3',
  '15': '4',
  '14': '5',
  '13': '6',
  '12': '7',
  '11': '8',
  '21': '9',
  '22': '10',
  '23': '11',
  '24': '12',
  '25': '13',
  '26': '14',
  '27': '15',
  '28': '16',
  '38': '17',
  '37': '18',
  '36': '19',
  '35': '20',
  '34': '21',
  '33': '22',
  '32': '23',
  '31': '24',
  '41': '25',
  '42': '26',
  '43': '27',
  '44': '28',
  '45': '29',
  '46': '30',
  '47': '31',
  '48': '32',
};

const reverseToothNumberingConversionTable: { [key: string]: string } = {
  '1': '18',
  '2': '17',
  '3': '16',
  '4': '15',
  '5': '14',
  '6': '13',
  '7': '12',
  '8': '11',
  '9': '21',
  '10': '22',
  '11': '23',
  '12': '24',
  '13': '25',
  '14': '26',
  '15': '27',
  '16': '28',
  '17': '38',
  '18': '37',
  '19': '36',
  '20': '35',
  '21': '34',
  '22': '33',
  '23': '32',
  '24': '31',
  '25': '41',
  '26': '42',
  '27': '43',
  '28': '44',
  '29': '45',
  '30': '46',
  '31': '47',
  '32': '48',
};
