import { TreatmentEnum } from 'src/common/enums';
import { WaitingTreatment } from 'src/common/types';

type Props = {
  formattedTreatments: WaitingTreatment[];
  initialTreatments: WaitingTreatment[];
  editingTreatments?: WaitingTreatment[];
};

export const getIdsToDelete = (props: Props): number[] => {
  const { formattedTreatments, initialTreatments, editingTreatments } = props;
  const allOnSurgeryIdsToDelete =
    initialTreatments
      .filter(
        ({ operationType, id }) => operationType.name === TreatmentEnum.ALL_ON_SURGERY && !!id
      )
      .map(({ id }) => id as number) ?? [];
  const currentTreatmentsToDelete =
    editingTreatments
      ?.filter(
        (initialTreatment) =>
          !formattedTreatments.find(({ id }) => initialTreatment.id === id) && !!initialTreatment.id
      )
      .map(({ id }) => id as number) ?? [];
  return [...allOnSurgeryIdsToDelete, ...currentTreatmentsToDelete];
};
