import { useCallback } from 'react';

import { AnalysisElement, ElementSubtype, XrayInput } from 'src/common/types';
import {
  Anatomies_Insert_Input,
  Elements_Insert_Input,
  GetPatientDocument,
  GetPatientXraysDocument,
  useUpdateXrayMutation,
} from 'src/communication/hasura/generated/graphql';
import { Anatomy } from 'src/ui/components/XrayAnnotationTool/types/Anatomy';
import { useElementTypes } from 'src/ui/pages/Patient/context';

type UseUpdateXray = () => {
  updateXray(
    id: string,
    xray: XrayInput,
    elements?: AnalysisElement[],
    anatomies?: Anatomies_Insert_Input[]
  ): Promise<void>;
};

const mapToElement = (
  xrayId: string,
  elementTypes: ElementSubtype[],
  elements?: AnalysisElement[]
): Elements_Insert_Input[] => {
  return (
    elements?.map((e) => ({
      coordinates: e.detectionPoly,
      detectionScore: e.detectionScore,
      typeId: elementTypes.find(({ name }) => name === e.elementType)?.id,
      fullId: elementTypes.find(({ name }) => name === e.elementTypeFull)?.id,
      xrayId,
      onTooth: e.onTooth,
    })) ?? []
  );
};

export const useUpdateXRay: UseUpdateXray = () => {
  const elementTypes = useElementTypes();
  const [updateXrayMutation] = useUpdateXrayMutation();

  const updateXray = useCallback(
    async (id: string, xray: XrayInput, elements?: AnalysisElement[], anatomies?: Anatomy[]) => {
      await updateXrayMutation({
        variables: {
          id,
          xray,
          elements: mapToElement(id, elementTypes, elements),
          withElements: !!elements,
          anatomies: anatomies ?? [],
          withAnatomies: !!anatomies,
        },
        refetchQueries: [GetPatientDocument, GetPatientXraysDocument],
      });
    },
    [elementTypes]
  );

  return { updateXray };
};
