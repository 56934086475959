import { StateCreator } from 'zustand';

import { buildXrayImage } from 'src/controller/consultation/buildXrayImage';

import { Store } from '..';
import { storeResetFns } from '../resetAllStore';
import { ElementFilterEnum } from '../xrayViewer/types';
import { PatientStore, PatientStoreState } from './types';

const initialPatientState: PatientStoreState = {
  currentPatient: undefined,
  displayedXrayId: undefined,
  currentConsultation: undefined,
  patientXrays: [],
  patientXrayImages: [],
  selectedPdfConsultationId: undefined,
};

const setDefaultXrayIds = (
  set: {
    (
      partial: Store | Partial<Store> | ((state: Store) => Store | Partial<Store>),
      replace?: boolean | undefined
    ): void;
  },
  get: { (): Store; (): Store }
) => {
  const store = get();
  const consultation = store.currentConsultation;
  if (!consultation || !store.patientXrays.length) {
    set({ displayedXrayId: undefined });
  } else {
    if (
      !store.displayedXrayId ||
      !store.patientXrays.find((xray) => xray.id === store.displayedXrayId)
    ) {
      const displayedXrayId = store.patientXrays.find(
        (xray) => xray.consultationId === consultation.id
      )?.id;
      set({ displayedXrayId: displayedXrayId ?? store.patientXrays[0].id });
    }
  }
};

export const usePatientStore: StateCreator<Store, [], [], PatientStore> = (set, get) => {
  storeResetFns.add(() => set(initialPatientState));
  return {
    ...initialPatientState,
    //Setters
    setCurrentPatient: (patient) => {
      const previousPatient = get().currentPatient;
      if (patient?.id && previousPatient?.id !== patient?.id) {
        //reset filters when patient change
        set({ hiddenElements: [] });
        set({ elementsFilterMode: ElementFilterEnum.ON });
      }
      set({ currentPatient: patient });
    },
    setCurrentConsultation: (consultation) => {
      let isNewConsultation = false;
      if (get().currentConsultation?.id !== consultation?.id) {
        isNewConsultation = true;
      }
      set({ currentConsultation: consultation });
      set({ selectedPdfConsultationId: consultation?.id });
      if (isNewConsultation) {
        const availableXraysId = get()
          .patientXrays.filter((xray) => xray.consultationId === consultation?.id)
          .map(({ id }) => id);
        const displayedXrayId = get().displayedXrayId;
        if (
          availableXraysId.length &&
          !(displayedXrayId && availableXraysId.includes(displayedXrayId))
        ) {
          set({ displayedXrayId: availableXraysId[0] });
        }
      }
    },
    setDisplayedXray: (xrayId: string | undefined) => set({ displayedXrayId: xrayId }),
    setSelectedPdfConsultation: (consultationId: number | undefined) =>
      set({ selectedPdfConsultationId: consultationId }),
    setPatientXrays: (xrays) => {
      set({ patientXrays: xrays });
      const xrayImages = xrays.map((xray) => buildXrayImage(xray));
      set({ patientXrayImages: xrayImages });
      setDefaultXrayIds(set, get);
    },
  };
};
