import { Icon } from '@allisone/react-components';
import { FC, useRef } from 'react';

import styles from './LocationIcon.module.scss';

type Props = {
  locationName: string;
};

const getColorFromString = (str: string): string => {
  if (str.length < 3) return 'white';
  const r = ((str.charCodeAt(0) * 255) % 155) + 100;
  const g = ((str.charCodeAt(1) * 255) % 155) + 100;
  const b = ((str.charCodeAt(2) * 255) % 155) + 100;
  return `rgb(${r}, ${g}, ${b})`;
};

export const LocationIcon: FC<Props> = ({ locationName }) => {
  const locationColor = getColorFromString(locationName);
  const iconRef = useRef<HTMLDivElement>(null);

  const colorIcon = () => {
    const svg = iconRef.current?.querySelector('svg');
    if (svg?.children) {
      for (let i = 0; i < svg.children.length; i++) {
        const child = svg.children[i];
        if (child.tagName === 'path') {
          child.setAttribute('fill', locationColor);
        }
      }
    }
  };

  return (
    <div className={styles.locationIcon} ref={iconRef}>
      <Icon iconName={'location'} onLoad={colorIcon} />
    </div>
  );
};
