import { ChildElementTypeEnum, ElementTypeEnum } from 'src/common/enums';
import { Report } from 'src/common/types';

const elementsIncludeDecay = (elements: string[]): boolean =>
  elements.includes(ChildElementTypeEnum.DECAY_CONTACT_NERVE) ||
  elements.includes(ChildElementTypeEnum.DECAY_NEAR_NERVE) ||
  elements.includes(ChildElementTypeEnum.ROOT_DECAY) ||
  elements.includes(ChildElementTypeEnum.DECAY_NO_NERVE_CONTACT);

export const getMissingAndDecayedTeethFromReport = (
  report: Report | null | undefined
): { decayedTeeth: string[]; missingTeeth: string[] } => {
  if (!report) return { decayedTeeth: [], missingTeeth: [] };

  const decayedTeeth = Object.entries(report.Teeth)
    .filter(([, elements]) => elementsIncludeDecay(elements))
    .flatMap(([tooth]) => tooth)
    .sort((a, b) => a.localeCompare(b));

  const missingTeeth = Object.entries(report.Teeth)
    .filter(([, elements]) => elements.includes(ElementTypeEnum.MISSING_TOOTH))
    .flatMap(([tooth]) => tooth)
    .sort((a, b) => a.localeCompare(b));

  return { decayedTeeth, missingTeeth };
};
