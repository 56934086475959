import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ClinicalExamsEnum } from 'src/common/enums/ClinicalExamsEnum';
import {
  registerUserEvent,
  useInsertClinicalMonitoring,
  useUpdateClinicalMonitoring,
  useUpdateConsultation,
} from 'src/communication/hasura/query';
import { useAppStore } from 'src/controller/store';
import { usePatientFileContext } from 'src/ui/pages/Patient/context';

import type { Clinic, ClinicExam, ClinicForm, ClinicValues } from '../types';
import { getInitialClinicalMonitoring } from './getInitialClinicalMonitoring';
import { getUpdatedClinicalExams } from './getUpdatedClinicalExams';
import { useCopyClinic } from './useCopyClinic';

const initialClinicalExams: ClinicExam[] = [
  {
    examName: ClinicalExamsEnum.DECAYED_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.DAMAGED_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.LOOSE_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.INFLAMMATION,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.MISSING_TEETH,
    teeth: [],
  },
  {
    examName: ClinicalExamsEnum.OCCLUSION,
    teeth: [],
  },
];

export const useClinicProvider = (): ClinicValues => {
  const { currentConsultation, currentPatient } = useAppStore();
  if (!currentConsultation) throw new Error('Consultation is not defined');

  const [activeTab, setActiveTab] = useState<number>(0);
  const {} = usePatientFileContext();
  const { clinicalMonitoring, id: consultationId, report } = currentConsultation;
  const { insertClinicalMonitoring } = useInsertClinicalMonitoring();
  const { updateConsultation } = useUpdateConsultation();
  const { getCopy } = useCopyClinic();
  const {
    esthetic,
    prosthesis,
    pain,
    occlusion,
    emergency,
    routineCheck,
    consultationComment,
    medicalBackground,
    initialBilanDate,
    lastConsultationDate,
    decayedTeeth,
    damagedTeeth,
    looseTeeth,
    inflammationTeeth,
    missingTeeth,
    occlusionTeeth,
  } = clinicalMonitoring ?? { decayedTeeth: [], missingTeeth: [] };

  const defaultValues: ClinicForm = useMemo(
    () => ({
      esthetic,
      prosthesis,
      pain,
      occlusion,
      emergency,
      routineCheck,
      consultationComment,
      medicalBackground,
      initialBilanDate: initialBilanDate ? new Date(initialBilanDate) : new Date(),
      lastConsultationDate: lastConsultationDate ? new Date(lastConsultationDate) : undefined,
    }),
    [
      consultationComment,
      emergency,
      esthetic,
      initialBilanDate,
      lastConsultationDate,
      medicalBackground,
      occlusion,
      pain,
      prosthesis,
      routineCheck,
    ]
  );

  const [save, setSave] = useState<boolean>(false);
  const [clinic, setClinic] = useState<Clinic>({
    decayedTeeth,
    damagedTeeth,
    looseTeeth,
    inflammationTeeth,
    missingTeeth,
    occlusionTeeth,
  });
  const { control, getValues, reset } = useForm({
    defaultValues,
  });
  const { updateClinicalMonitoring, error } = useUpdateClinicalMonitoring();
  const [selectedExam, setSelectedExam] = useState<ClinicalExamsEnum>(
    ClinicalExamsEnum.DECAYED_TEETH
  );
  const clinicalExams: ClinicExam[] = useMemo(
    () =>
      initialClinicalExams.map(({ examName }) => ({
        examName,
        teeth: clinic[examName],
      })),
    [clinic]
  );

  // Refresh state when changing pano
  useEffect(() => {
    // Insert clinic if it does not exist
    if (!clinicalMonitoring) {
      const newClinicalMonitoring = getInitialClinicalMonitoring(consultationId, report);
      if (!newClinicalMonitoring) return;
      insertClinicalMonitoring(newClinicalMonitoring.data)
        .then(({ data }) => {
          updateConsultation(consultationId, {
            clinical_monitoring_id: data?.insert_clinical_monitoring_one?.id,
          }).catch();
        })
        .catch();
      setClinic({
        decayedTeeth: newClinicalMonitoring.data.decayedTeeth,
        damagedTeeth: newClinicalMonitoring.data.damagedTeeth,
        looseTeeth: newClinicalMonitoring.data.looseTeeth,
        inflammationTeeth: newClinicalMonitoring.data.inflammationTeeth,
        missingTeeth: newClinicalMonitoring.data.missingTeeth,
        occlusionTeeth: newClinicalMonitoring.data.occlusionTeeth,
      });
    } else {
      reset(defaultValues);
      setClinic({
        decayedTeeth,
        damagedTeeth,
        looseTeeth,
        inflammationTeeth,
        missingTeeth,
        occlusionTeeth,
      });
    }
  }, [consultationId]);

  // Update clinic state if xray was edited
  useEffect(() => {
    if (!clinicalMonitoring) return;
    const updatedClinicalExams = getUpdatedClinicalExams(clinicalMonitoring, report);
    setClinic(updatedClinicalExams);
  }, [report]);

  const deleteTooth = (exam: ClinicalExamsEnum, tooth: string) => {
    const teeth: string[] = [...clinic[exam]];
    const toothIndex = teeth.findIndex((element: string) => element === tooth);
    if (toothIndex === -1) return;
    teeth.splice(toothIndex, 1);
    setSave(true);
    setClinic({
      ...clinic,
      [exam]: teeth,
    });
  };

  const onSubmit = async () => {
    if (!clinicalMonitoring) return;
    const formValues = getValues();
    updateClinicalMonitoring(clinicalMonitoring.id, {
      ...formValues,
      ...clinic,
      initialBilanDate: formValues.initialBilanDate?.toISOString(),
      lastConsultationDate: formValues.lastConsultationDate?.toISOString(),
    }).catch();
  };

  const nextStep = () => setActiveTab(activeTab + 1);

  const handleCopy = () => {
    registerUserEvent('patient.clinic.copyContent', {
      consultationId,
      patientId: currentPatient?.id,
    });
    const formValues = getValues();
    const copy = getCopy(formValues, clinic);
    return copy;
  };

  return {
    save,
    setSave,
    error,
    clinic,
    setClinic,
    control,
    onSubmit,
    clinicalExams,
    selectedExam,
    setSelectedExam,
    deleteTooth,
    activeTab,
    setActiveTab,
    nextStep,
    handleCopy,
  };
};
