import { Button, getClasses, Icon } from '@allisone/react-components';
import * as Sentry from '@sentry/react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useAppStore } from 'src/controller/store';

import styles from './Error.module.scss';

type Props = {
  error: Error | string;
  resetError?(): void;
};

export const Error: FC<Props> = ({ error, resetError }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { user } = useAppStore();

  useEffect(() => {
    Sentry.captureException(error, {
      extra: { reason: 'Unhandled error in Dentist app (shows the error page).' },
    });
  }, []);

  const onClick = () => {
    resetError?.();
    navigateTo('/');
  };

  return (
    <div className={getClasses(styles.errorPage, user && styles.loggedIn)}>
      <div className={styles.leftPart}>
        <div className={styles.title}>{t('errorGeneric')}.</div>
        <div className={styles.explanation}>
          {typeof error === 'string' ? error : error.message}
        </div>
        <div>
          <Button testId="error-page-button" onClick={onClick} label={t('home')} />
        </div>
      </div>
      <div className={styles.illustration}>
        <Icon iconName="errorPage" />
      </div>
    </div>
  );
};
