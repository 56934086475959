import { FC, lazy, LazyExoticComponent } from 'react';

import { parseLocalStorage } from 'src/controller/app/parseLocaleStorage';
import { RETRY_LAZY_REFRESHED } from 'src/controller/constants';

export const lazyWithRetry = (
  componentImport: () => Promise<{ default: FC<Record<string, unknown>> }>
): LazyExoticComponent<FC<Record<string, unknown>>> =>
  lazy(async () => {
    // Check if the window has already been refreshed
    const lazyRefreshed = parseLocalStorage<boolean>(RETRY_LAZY_REFRESHED) ?? false;

    try {
      // try to import the component
      const component = await componentImport();
      window.localStorage.setItem(RETRY_LAZY_REFRESHED, 'false');
      return component;
    } catch (error) {
      if (!lazyRefreshed) {
        window.localStorage.setItem(RETRY_LAZY_REFRESHED, 'true');
        // Refresh the page
        window.location.reload();
      }
      // Default error behaviour as already tried refresh
      throw error;
    }
  });
