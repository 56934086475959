import { Annotation, EditionMode } from '../../ui/components/XrayAnnotationTool/types';
import { ElementTypeEnum } from 'src/common/enums';

export const editAnnotationColor = (annotation: Annotation) => {
  return annotation.type.nameKey === ElementTypeEnum.RADIOLUCENCY
    ? {
        ...annotation,
        type: {
          ...annotation.type,
          color: 'var(--color-radiolucency-identification)',
        },
        parentType: annotation.parentType
          ? {
              ...annotation.parentType,
              color: 'var(--color-radiolucency-identification)',
            }
          : undefined,
      }
    : annotation;
};

export const changeRadiolucencyColors = (
  annotations: Annotation[] | undefined,
  editionMode: EditionMode
): Annotation[] | undefined => {
  return editionMode === EditionMode.IDENTIFY_RADIOLUCENCY
    ? annotations?.map(editAnnotationColor)
    : annotations;
};
