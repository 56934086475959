import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { Role } from 'src/common/enums';
import {
  GetLocationsQuery,
  useGetLocationsLazyQuery,
  useGetTreatmentPreferencesLazyQuery,
} from 'src/communication/hasura/generated/graphql';
import { buildInitialTreatmentPreferences } from 'src/controller/dentistry/treatmentPreferences';

import {
  useInsertTreatmentPreferences,
  useUpdateTreatmentPreferences,
} from '../../communication/api/treatmentPreferences';
import { useAppStore } from '../store';
import { isHeadOf } from './User';

export const useInsertDefaultTreatmentPreferences = async () => {
  const [getTreatmentPreferences] = useGetTreatmentPreferencesLazyQuery();
  const insertTreatmentPreferences = useInsertTreatmentPreferences();
  const updateTreatmentPreferences = useUpdateTreatmentPreferences();
  const [getLocations] = useGetLocationsLazyQuery();
  const { user, currentLocation, currentRoles } = useAppStore();
  const auth = useAuth();

  const applyDefaultTreatmentPreferences = async () => {
    if (!user) return;
    await insertTreatmentPreferences({
      treatmentPreferences: {
        userId: user.id,
        treatmentPreferences: buildInitialTreatmentPreferences(),
      },
    });
  };

  const applyHeadOfTreatmentPreferences = async (headOfUserId: number) => {
    if (!user) return;
    const userTreatmentPreferences = user.treatmentPreferences?.treatmentPreferences;
    const { data } = await getTreatmentPreferences({ variables: { userId: headOfUserId } });
    const headOfPreferences = data?.treatment_preferences[0]?.treatmentPreferences;
    if (headOfPreferences) {
      const hasSamePreferencesAsHeadOf = isEqual(userTreatmentPreferences, headOfPreferences);
      if (!user.treatmentPreferences || !hasSamePreferencesAsHeadOf) {
        if (!user.treatmentPreferences?.id) {
          await insertTreatmentPreferences({
            treatmentPreferences: {
              userId: user.id,
              treatmentPreferences: headOfPreferences,
            },
          });
        } else {
          await updateTreatmentPreferences({
            treatmentPreferences: headOfPreferences,
            userId: user.id,
          });
        }
      }
    } else {
      await applyDefaultTreatmentPreferences();
    }
  };

  const handleDentistAtOffice = async () => {
    if (!user) return;
    const userTreatmentPreferences = user.treatmentPreferences?.treatmentPreferences;
    if (!userTreatmentPreferences) {
      await applyDefaultTreatmentPreferences();
    }
  };

  const handleDentistAtCenter = async (location?: GetLocationsQuery['locations'][0]) => {
    const headOfUserId = location?.locations_users.find(({ user: locationUser }) =>
      isHeadOf(locationUser.roles.map(({ role }) => role.value as Role))
    )?.user.id;
    if (headOfUserId) {
      await applyHeadOfTreatmentPreferences(headOfUserId);
    } else {
      await applyDefaultTreatmentPreferences();
    }
  };

  const fetchData = async () => {
    if (!user || !currentRoles.length) return;
    const { data: locationsData } = await getLocations();
    const location =
      locationsData?.locations.find(({ id }) => id === currentLocation) ||
      locationsData?.locations[0];
    if (currentRoles.includes(Role.HEAD_OF)) {
      await applyDefaultTreatmentPreferences();
    } else if (currentRoles.includes(Role.DENTIST) || currentRoles.includes(Role.DIRECTOR)) {
      if (location?.type === 'center') {
        await handleDentistAtCenter(location);
      } else {
        await handleDentistAtOffice();
      }
    }
  };

  useEffect(() => {
    if (!user || !auth.isAuthenticated) return;
    const timeoutId = setTimeout(() => {
      const userTreatmentPreferences = user.treatmentPreferences?.treatmentPreferences;
      if (userTreatmentPreferences) return;
      fetchData();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [user, currentLocation, auth]);
};
