import { useCallback, useMemo } from 'react';

import { OperationTypeSchema } from 'src/common/types';
import { useOperationTypesQuery } from 'src/communication/hasura/generated/graphql';
import { useErrorManager } from 'src/controller/app/useErrorManager';

export const useOperationTypes = () => {
  const { data, loading, error } = useOperationTypesQuery();
  useErrorManager(error);

  const operationTypes = useMemo(
    () => (data?.operation_types ?? []) as OperationTypeSchema[],
    [data?.operation_types]
  );

  const getOperationType = useCallback(
    (operationId: number): OperationTypeSchema | undefined => {
      const operation = operationTypes.find((operationType) => operationType.id === operationId);
      return operation;
    },
    [operationTypes]
  );

  return { operationTypes, loading, getOperationType };
};
