import * as Sentry from '@sentry/react';

import { axiosPost } from './utils/axiosUtils';
import { Analysis } from 'src/common/types';
import { getAllowedRoles, getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { getDefaultRole } from 'src/controller/user/User';

type partialAnalysis = Pick<
  Analysis,
  | 'teeth'
  | 'elements'
  | 'anatomies'
  | 'periodontics'
  | 'imageType'
  | 'imageHeight'
  | 'imageWidth'
  | 'bridges'
>;

//Precondition : image must be uploaded in the temp bucket
export const editXrayAnalysis = async (analysis: partialAnalysis) => {
  const authTokens = getAuthTokens() ?? undefined;
  const currentRoles = getAllowedRoles();
  const role = getDefaultRole(currentRoles);

  try {
    if (!authTokens || !role) throw new Error('Token or role not found');
    const { data } = await axiosPost({
      url: '/api/v2/internal/ml/edit',
      token: authTokens,
      role,
      data: { analysis },
    });
    return data.analysis as Analysis;
  } catch (error) {
    Sentry.captureException(error, { extra: { url: '/api/v2/internal/ml/edit' } });
    throw new Error(error as any);
  }
};
