import { Report } from 'src/common/types';
import { ClinicalCommonFieldsFragment } from 'src/communication/hasura/generated/graphql';
import { getMissingAndDecayedTeethFromReport } from 'src/controller/consultation/getMissingAndDecayedTeethFromReport';

import { Clinic } from '../types/Clinic';

const getUpdatedClinicalExamEntries = (
  clinicalExamEntriesFromReport: string[],
  clinicalExamEntries: string[]
): string[] => {
  const entriesFromClinicalExams = clinicalExamEntries.filter(
    (entry: string) => !clinicalExamEntriesFromReport.includes(entry)
  );
  const updatedClinicalExamEntriesWithDuplicates = clinicalExamEntriesFromReport
    .concat(entriesFromClinicalExams)
    .sort((a, b) => a.localeCompare(b));
  const updatedClinicalExamEntries = updatedClinicalExamEntriesWithDuplicates.filter(
    (entry, index) => updatedClinicalExamEntriesWithDuplicates.indexOf(entry) === index
  );
  return updatedClinicalExamEntries;
};

export const getUpdatedClinicalExams = (
  clinicalMonitoring: ClinicalCommonFieldsFragment,
  report: Report
): Clinic => {
  const {
    damagedTeeth,
    looseTeeth,
    inflammationTeeth,
    occlusionTeeth,
    decayedTeeth,
    missingTeeth,
  } = clinicalMonitoring;

  const { decayedTeeth: decayedTeethFromReport, missingTeeth: missingTeethFromReport } =
    getMissingAndDecayedTeethFromReport(report);

  const updatedDecayedTeeth = getUpdatedClinicalExamEntries(decayedTeethFromReport, decayedTeeth);
  const updatedMissingTeeth = getUpdatedClinicalExamEntries(missingTeethFromReport, missingTeeth);

  return {
    decayedTeeth: updatedDecayedTeeth,
    damagedTeeth,
    looseTeeth,
    inflammationTeeth,
    missingTeeth: updatedMissingTeeth,
    occlusionTeeth,
  };
};
