import { Dropdown, useToaster } from '@allisone/react-components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from 'src/common/enums';
import { useConfig } from 'src/controller/context/config/useConfigContext';
import { useAppStore } from 'src/controller/store';

type Props = {
  formDesign: boolean;
};

export const LanguageDropdown: FC<Props> = (props) => {
  const { t } = useTranslation('settings');
  const { changeLocale, languageOptions } = useConfig();
  const { user, loading, locale } = useAppStore();
  const [selectedLocale, setSelectedLocale] = useState<Locale>(user?.locale ?? Locale.Fr);
  const { show } = useToaster();

  const options = languageOptions.map(({ label, value }) => ({
    label,
    value,
    selected: value === selectedLocale,
  }));

  const handleChange = (options: { label: string; value: string; selected: boolean }[]) => {
    try {
      const selectedOne = options.filter((option) => option.selected)[0];
      if (locale !== selectedOne.value) {
        setSelectedLocale(selectedOne.value as Locale);
        changeLocale(selectedOne.value as Locale);
      }
    } catch (e) {
      show({ type: 'error', text: 'error when setting locale' + e });
    }
  };

  // Don't show language selection if there is only one option
  if (languageOptions.length <= 1) return null;

  return (
    <Dropdown
      label={props.formDesign ? t('language.title') : ''}
      formDesign={props.formDesign}
      onSelect={handleChange}
      options={options}
      disabled={loading}
      icon="globe"
    />
  );
};
