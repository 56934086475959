import { StateCreator } from 'zustand';

import { Store } from '..';
import { storeResetFns } from '../resetAllStore';
import { PatientsStore, PatientsStoreState } from './types';

const initialPatientsState: PatientsStoreState = {
  patientsSearchValue: '',
  patientsSortOptions: { sortBy: 'id', order: 1 },
  patientsUserFilter: undefined,
  patientsLocationFilter: undefined,
  patients: [],
  totalPatients: 0,
};

export const usePatientsStore: StateCreator<Store, [], [], PatientsStore> = (set, _get) => {
  storeResetFns.add(() => set(initialPatientsState));
  return {
    ...initialPatientsState,
    //Setters
    setPatientsSearchValue(value) {
      set({ patientsSearchValue: value });
    },
    setPatientsSortOptions(options) {
      set({ patientsSortOptions: options });
    },
    setPatientsUserFilter(filter) {
      set({ patientsUserFilter: filter });
    },
    setPatientsLocationFilter(filter) {
      set({ patientsLocationFilter: filter });
    },
    setTotalPatients(totalPatients) {
      set({ totalPatients });
    },
    setPatients(patients) {
      set({ patients });
    },
  };
};
