import { useEffect, useState } from 'react';

import { useLocations } from 'src/communication/hasura/query';
import { areTreatmentPreferencesAvailable } from 'src/controller/app/areTreatmentPreferencesAvailable';
import { useAppStore } from 'src/controller/store';

export const useTreatmentPreferencesAllowed = () => {
  const { currentRoles, currentLocation } = useAppStore();
  const { locations } = useLocations();
  const [areTreatmentPreferencesAllowed, setAreTreatmentPreferencesAllowed] = useState(false);

  useEffect(() => {
    const location = locations?.find(({ id }) => id === currentLocation) || locations?.[0];
    const isTreatmentPreferencesEnabled = location?.isTreatmentPreferencesEnabled ?? false;
    setAreTreatmentPreferencesAllowed(
      areTreatmentPreferencesAvailable(currentRoles, location?.type, isTreatmentPreferencesEnabled)
    );
  }, [currentRoles, locations]);

  return {
    areTreatmentPreferencesAllowed,
  };
};
