import { Button, Modal } from '@allisone/react-components';
import { isValid } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EnrichedBilling } from 'src/common/types';
import { getCurrency } from 'src/controller/basics/getCurrency';
import { useFormatDate } from 'src/controller/basics/useDate';

import styles from './BillingDetailsModal.module.scss';

type Props = {
  billing: EnrichedBilling;
  isModalVisible: boolean;
  setModalVisibility: (isVisible: boolean) => void;
};

export const BillingDetailsModal: FC<Props> = ({ isModalVisible, setModalVisibility, billing }) => {
  const { prorata } = billing;

  const formatDate = useFormatDate();
  const { t } = useTranslation('centrePaymentModal');
  const currency = getCurrency(billing.price.currency);

  if (!prorata) return null;

  const invoiceStartDate =
    isValid(new Date(prorata.invoiceStartDate)) &&
    formatDate(new Date(prorata.invoiceStartDate), 'PP');

  const invoiceNextDate =
    isValid(new Date(prorata.invoiceNextDate)) &&
    formatDate(new Date(prorata.invoiceNextDate), 'PP');
  const details = `${prorata.details.numIntervalsToPay} ${t(
    `interval.${prorata.details.interval}`
  )} x ${billing.quantity} ${t('columns.formula').toLowerCase()}`;

  return (
    <div className={styles.billingDetailsModal}>
      <Modal testId="billingDetailsModal" isOpened={isModalVisible}>
        <div className={styles.detailsModalContent}>
          <div className={styles.title}>
            <div className={styles.bold}>{t('amountDetailsTitle')}</div>
            <div>{t('amountDetails', { invoiceStartDate, invoiceNextDate })} </div>
          </div>
          <div className={styles.details}>
            <div className={styles.detail}>
              {details}
              <div>
                {prorata.totalBeforeDiscountExclTax / 100} {currency}
              </div>
            </div>
            {prorata.discountName && (
              <div className={styles.detail}>
                {prorata.discountName}
                <div>
                  {prorata.discountValue / 100} {currency}
                </div>
              </div>
            )}
            <div className={styles.detail}>
              {t('columns.totalPriceExclTax')}
              <div>
                {prorata.totalExclTax / 100} {currency}
              </div>
            </div>
            <div className={styles.detail}>
              {t('columns.tax')}
              <div>
                {prorata.taxAmount / 100} {currency}
              </div>
            </div>
            <div className={styles.detail}>
              {t('columns.totalPriceInclTax')}
              <div>
                {prorata.totalInclTax / 100} {currency}
              </div>
            </div>
          </div>
          <div className={styles.button}>
            <Button
              testId="closeBillingDetails"
              onClick={() => setModalVisibility(false)}
              label="OK"
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
