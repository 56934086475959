import { createContext } from 'react';

import { usePageProvider } from './usePageProvider';
import { CFC } from 'src/ui/components/FCWithChildren';

export type ModalType = null | 'email' | 'print' | 'illustratedTreatments' | 'notes';

export type PageValues = {
  isModalPdfOpen: boolean;
  setIsModalPdfOpen(isModalPdfOpen: boolean): void;
  modalType: ModalType;
  setModalType(modalType: ModalType): void;
};

export const PageContext = createContext<PageValues | null>(null);

export const PageProvider: CFC = ({ children }) => (
  <PageContext.Provider value={usePageProvider()}>{children}</PageContext.Provider>
);
