import { useTranslation } from 'react-i18next';

import { getLocaleFromString } from '../app/getLocaleFromString';
import { useAppStore } from '../store';

// Please check that the files that you need are available in all the languages specified in this hook
export const useGetFilePath = (fileName: string) => {
  const { i18n } = useTranslation();
  const { user } = useAppStore();
  const userLocale = user?.locale;
  let locale = getLocaleFromString(userLocale ?? i18n.language);
  const S3_URL = import.meta.env.VITE_ALLISONE_WEBAPP_S3;
  return `${S3_URL}/${locale}/${fileName}`;
};
