import { useCallback } from 'react';

import {
  CompleteActsMutationVariables,
  GetConsultationDocument,
  useCompleteActsMutation,
} from 'src/communication/hasura/generated/graphql';

export const useCompleteActs = () => {
  const [completeActsMutation] = useCompleteActsMutation({
    refetchQueries: [GetConsultationDocument],
  });

  const completeActs = useCallback((variables: CompleteActsMutationVariables) => {
    completeActsMutation({ variables });
  }, []);

  return { completeActs };
};
