import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { PatientStore } from './patient/types';
import { usePatientGetters } from './patient/usePatientGetter';
import { usePatientStore } from './patient/usePatientStore';
import { PatientsStore } from './patients/types';
import { usePatientsStore } from './patients/usePatientsStore';
import type { UserStore } from './user/types';
import { useUserGetters } from './user/useUserGetter';
import { useUserStore } from './user/useUserStore';
import { XrayViewerStore } from './xrayViewer/types';
import { useXrayViewerStore } from './xrayViewer/useViewerStore';

export type Store = UserStore & PatientStore & XrayViewerStore & PatientsStore;

export const useAppStore = create<Store>()(
  persist(
    (...actions) => ({
      ...useUserStore(...actions),
      ...usePatientStore(...actions),
      ...usePatientsStore(...actions),
      ...useXrayViewerStore(...actions),
    }),
    {
      name: 'dental-webapp-store',
      // Include only user infos in local storage
      partialize: (state) => ({
        user: state.user,
        loading: state.loading,
        currentLocation: state.currentLocation,
        currentRoles: state.currentRoles,
        currentRole: state.currentRole,
        locale: state.locale,
        isClinicBetaPilot: state.isClinicBetaPilot,
        isAllisonePlusV2Activated: state.isAllisonePlusV2Activated,
        isEducationalSheetEnabled: state.isEducationalSheetEnabled,
        reseller: state.reseller,
        locationVersions: state.locationVersions,
      }),
    }
  )
);

export const useAppGetters = () => {
  return {
    ...usePatientGetters(),
    ...useUserGetters(),
  };
};
