import { v4 as uuidv4 } from 'uuid';

import { Pathology } from '../../../common/types/dentistry/treatmentPreferences';

export const buildInitialPreference = (pathology: string): Pathology => ({
  name: pathology,
  options: [
    {
      dentalSchemaCategory: 'all',
      treatments: [{ id: uuidv4(), label: '', value: '' }],
      id: uuidv4(),
    },
  ],
});
