import { implantIdentificationStatus } from '../../TooltipSpotimplant/data';
import { ImplantIdentification } from '../../TooltipSpotimplant/types';
import { ApiCredentials } from '../types';
import { getIdentificationResult } from './getIdentificationResult';

const SPOTIMPLANT_API_URL = import.meta.env.VITE_SPOTIMPLANT_API_URL;

type Props = {
  xrayId: string;
  apiCredentials: ApiCredentials;
  customIds?: string[];
};

export const listIdentifications = async ({
  customIds,
  xrayId,
  apiCredentials,
}: Props): Promise<ImplantIdentification[]> => {
  const params = new URLSearchParams({
    ...(customIds && customIds.length && { custom_id: customIds.join(',') }),
    ...(xrayId && { xray_id: xrayId }),
  });

  const url = `${SPOTIMPLANT_API_URL}/api/v1/identifications/?${params.toString()}`;

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${apiCredentials.token}`,
      'x-hasura-role': String(apiCredentials.role),
    },
  });
  if (!response.ok) throw 'Error fetching identifications';

  const items = (await response.json())?.results ?? [];

  const itemsWithResult = await Promise.all(
    items.map(async (item: ImplantIdentification) => {
      if (item.status !== implantIdentificationStatus.FINISHED) {
        return item;
      }
      const result = await getIdentificationResult({
        id: item.id,
        apiCredentials,
      });
      return {
        ...item,
        result,
      };
    })
  );

  return itemsWithResult;
};
