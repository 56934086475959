import { useContext } from 'react';

import { SpotimplantContext } from '../context/SpotimplantContext';
import { SpotimplantContextValues } from '../types';

export const useSpotimplantContext = (): SpotimplantContextValues => {
  const value = useContext(SpotimplantContext);
  if (!value)
    throw new Error('useSpotimplantContext can only be used in children of SpotimplantProvider');
  return value;
};
