import { createContext, useContext } from 'react';

import { useConfigProvider } from './useConfigProvider';
import { Locale } from 'src/common/enums';
import { CFC } from 'src/ui/components/FCWithChildren';

export type ConfigContextValues = {
  isLoading: boolean;
  changeLocale(selectedLocale: Locale): void;
  languageOptions: { label: string; value: Locale }[];
  setDemoCompletionDate(): void;
};

export const ConfigProvider: CFC = ({ children }) => {
  const values = useConfigProvider();
  return <ConfigContext.Provider value={values}>{children}</ConfigContext.Provider>;
};

const ConfigContext = createContext<ConfigContextValues | null>(null);
export const useConfig = (): ConfigContextValues => {
  const values = useContext(ConfigContext);
  if (!values) throw new Error(`useConfig can only be used in children of ConfigContext`);
  return values;
};
