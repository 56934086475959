import { Dropdown, Icon, LottieAnimation, ProfilePicture } from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router';

import { clearStorage } from 'src/communication/apollo/clearStorage';
import { useGetStripePortalUrls, useLocations } from 'src/communication/hasura/query';
import { useAppStore } from 'src/controller/store';
import { resetAllStore } from 'src/controller/store/resetAllStore';
import { isManager } from 'src/controller/user/User';
import { useTreatmentPreferencesAllowed } from 'src/controller/user/useTreatmentPreferencesAllowed';
import { useGetImageFromCdn } from 'src/ui/components/Image';

import styles from './HeaderMenu.module.scss';

type MenuOptions = {
  label: string;
  action: () => void;
  prefix?: JSX.Element;
  selected?: boolean;
};

export const HeaderMenu: FC = () => {
  const { user, currentRoles, isEducationalSheetEnabled } = useAppStore();
  const navigateTo = useNavigate();
  const { url, loading } = useGetImageFromCdn(user?.image);
  const { locations } = useLocations();
  const { areTreatmentPreferencesAllowed } = useTreatmentPreferencesAllowed();
  const hasOneLocation = locations?.length === 1;
  const auth = useAuth();
  const { data } = useGetStripePortalUrls();
  const isInvoicesLinkVisible = !isManager(currentRoles) && data && data?.length > 0;

  const { t } = useTranslation([
    'about',
    'layout',
    'settings',
    'treatmentPreferences',
    'educationalSheet',
  ]);

  if (!user) return null;

  const options: MenuOptions[] = [
    {
      label: t('settings:title'),
      action: () => {
        navigateTo('/account');
      },
      prefix: <Icon iconName="settings" />,
    },
  ];

  if (isManager(currentRoles)) {
    options.push({
      label: hasOneLocation ? t('location:myCenter') : t('location:myCenters'),
      action: () => {
        navigateTo(hasOneLocation ? `/locations/${locations?.[0]?.id}` : '/locations');
      },
      prefix: <Icon iconName="centers" />,
    });
  }

  if (areTreatmentPreferencesAllowed) {
    options.push({
      label: t('treatmentPreferences:myTreatments'),
      action: () => {
        navigateTo('/preferences');
      },
      prefix: <Icon iconName="treatments" />,
    });
  }

  if (isInvoicesLinkVisible) {
    options.push({
      label: t('settings:invoices.title'),
      action: () => {
        navigateTo('/invoices');
      },
      prefix: <Icon iconName="billings" />,
    });
  }

  if (isEducationalSheetEnabled) {
    options.push({
      label: t('educationalSheet:educationalSheets'),
      action: () => {
        navigateTo('/educational-sheets');
      },
      prefix: <Icon iconName="educationnal" />,
    });
  }

  options.push({
    label: t('about:title'),
    action: () => {
      navigateTo('/about');
    },
    prefix: <Icon iconName="about" />,
  });

  const onLogout = () => {
    clearStorage();
    resetAllStore();
    auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    });
  };

  options.push({
    label: t('layout:header.logout'),
    action: onLogout,
    prefix: <Icon iconName="logout" />,
  });

  const handleClick = (options: MenuOptions[]) => {
    options.filter((o) => o.selected).forEach((o) => o.action());
  };

  return (
    <div className={styles.headerMenu}>
      <Dropdown options={options.map((o) => ({ ...o, selected: false }))} onSelect={handleClick}>
        <div className={styles.profilePicture}>
          {loading ? (
            <LottieAnimation name="spinner" />
          ) : (
            <ProfilePicture
              size="big"
              imageSrc={url}
              firstname={user.firstname}
              lastname={user.lastname}
            />
          )}
        </div>
      </Dropdown>
    </div>
  );
};
