import {
  GetConsultationDocument,
  UpdateConsultationMutationVariables,
  useUpdateConsultationMutation,
} from 'src/communication/hasura/generated/graphql';

type Return = {
  updateConsultation: (
    id: UpdateConsultationMutationVariables['id'],
    treatment: UpdateConsultationMutationVariables['consultation']
  ) => Promise<void>;
  loading: boolean;
};

export const useUpdateConsultation = (): Return => {
  const [updateTreatment, { loading }] = useUpdateConsultationMutation({
    refetchQueries: [GetConsultationDocument],
  });

  const updateConsultation = async (
    id: UpdateConsultationMutationVariables['id'],
    consultation: UpdateConsultationMutationVariables['consultation']
  ) => {
    await updateTreatment({ variables: { id, consultation } });
  };

  return { updateConsultation, loading };
};
