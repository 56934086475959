import { gql } from '@apollo/client';
import { useCallback } from 'react';

import { WaitingTreatment } from 'src/common/types';
import { client as apolloClient } from 'src/communication/apollo';
import { GetConsultationDocument } from 'src/communication/hasura/generated/graphql';

const getUpdateActsMutation = (actsToUpdate: WaitingTreatment[]) => gql`
  mutation UpdateActs(
    $consultationId: Int!
    $ids: [Int!]!
    $acts: [operations_insert_input!]!
  ) {
    update_consultation(
      pk_columns: { id: $consultationId }
      _set: { fromMigration: false }
    ) {
      id
      fromMigration
    }

    delete_operations(where: { id: { _in: $ids } }) {
      affected_rows
    }

    create_operations(objects: $acts) {
      affected_rows
    }
    
    ${actsToUpdate.map(
      (a) => `
        update_operation(pk_columns: {id: ${a.id}}, _set: {toothNumbers: [${a.toothNumbers.map(
        (tooth) => `"${tooth}"`
      )}]}) {
            id
            toothNumbers
        }`
    )}
  }
`;

type Props = {
  consultationId: number;
  idsToDelete: number[];
  actsToUpdate: WaitingTreatment[];
  actsToCreate: WaitingTreatment[];
};

export const useUpdateActs = () => {
  const updateActs = useCallback(
    async ({ consultationId, idsToDelete, actsToUpdate, actsToCreate }: Props) => {
      if (idsToDelete.length === 0 && actsToCreate.length === 0 && actsToUpdate.length === 0) {
        return;
      }

      await apolloClient.mutate({
        mutation: getUpdateActsMutation(actsToUpdate),
        variables: {
          consultationId,
          ids: idsToDelete,
          acts: actsToCreate.map((act, order) => ({
            order,
            consultationId,
            toothNumbers: act.toothNumbers,
            pillarTeeth: act.pillarTeeth,
            completedAt: null,
            operationTypeId: act.operationType.id,
            groupId: act.groupId,
          })),
        },
        refetchQueries: [GetConsultationDocument],
        awaitRefetchQueries: true,
      });
    },
    []
  );

  return { updateActs };
};
