import { useEffect, useState } from 'react';

import { useAppStore } from 'src/controller/store';

type Return = {
  isAllisonePlusV2Activated: boolean;
  loading: boolean;
  hasAccessToAllisonePlus: boolean;
};

export const useIsElectronBetaTester = (): Return => {
  const { user, loading, isAllisonePlusV2Activated } = useAppStore();

  const [hasAccessToAllisonePlus, setHasAccessToAllisonePlus] = useState(false);

  useEffect(() => {
    if (user) {
      const hasOneLocationBetaPilot = user.locations?.some(
        ({ location }) => location.isWatcherBetaPilot
      );
      if (isAllisonePlusV2Activated || hasOneLocationBetaPilot) setHasAccessToAllisonePlus(true);
    }
  }, [user]);

  return {
    isAllisonePlusV2Activated,
    loading,
    hasAccessToAllisonePlus,
  };
};
