import { useState } from 'react';

import { ModalType, PageValues } from './PageProvider';

export const usePageProvider = (): PageValues => {
  const [isModalPdfOpen, setIsModalPdfOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(null);

  return {
    isModalPdfOpen,
    setIsModalPdfOpen,
    modalType,
    setModalType,
  };
};
