import { useToaster } from '@allisone/react-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WaitingTreatment } from 'src/common/types';
import { registerUserEvent, useActs } from 'src/communication/hasura/query';
import { getActsToCreate } from 'src/controller/consultation/getActsToCreate';
import { getIdsToDelete } from 'src/controller/consultation/getIdsToDelete';
import { useAppStore } from 'src/controller/store';

import { useConstructionContext } from '../../Consultation/PlanSide/components/Construction/ConstructionProvider/useConstructionContext';
import { formatTreatments } from '../../Consultation/PlanSide/helpers';
import { usePlanSide } from '../../Consultation/PlanSide/hooks';
import { TreatmentModalEditionType, TreatmentModalValues } from './TreatmentModalProvider';

export const useTreatmentModalProvider = (): TreatmentModalValues => {
  const { currentConsultation, currentPatient } = useAppStore();
  if (!currentConsultation) throw new Error('Consultation is not defined');
  const { t } = useTranslation('treatment');

  const { show } = useToaster();
  const { setRefreshProjection } = usePlanSide();
  const { createTreatments } = useConstructionContext();
  const { updateActs } = useActs();

  const [showTreatmentModal, setShowTreatmentModal] = useState(false);
  const [treatmentsToEdit, setTreatmentsToEdit] = useState<WaitingTreatment[]>([]);
  const [treatmentModalEditionType, setTreatmentModalEditionType] =
    useState<TreatmentModalEditionType | null>(null);

  const treatmentPlanActs = useMemo(
    () =>
      currentConsultation.operations.map((o) => ({
        id: o.id,
        operationType: o.operationType,
        toothNumbers: o.toothNumbers,
        pillarTeeth: o.pillarTeeth,
        groupId: o.groupId as number | undefined,
      })),
    [currentConsultation.operations]
  );
  const options = {
    consultationId: currentConsultation.id,
    patientId: currentPatient?.id,
  };

  const openAddTreatment = (): void => {
    registerUserEvent('treatment.ttt.edit.action.addTreatment', options);
    setTreatmentModalEditionType('create');
    setShowTreatmentModal(true);
  };

  const openEditTreatment = (treatments: WaitingTreatment[]): void => {
    registerUserEvent('treatment.ttt.edit.action.editTreatment', options);
    setShowTreatmentModal(true);
    setTreatmentModalEditionType('edit');
    setTreatmentsToEdit(treatments);
  };

  const openUpdateTreatmentPlan = (): void => {
    registerUserEvent('treatment.ttt.edit.action.addTreatment', options);
    setShowTreatmentModal(true);
    setTreatmentModalEditionType('updateAndSave');
  };

  const openEditTreatmentPlan = (treatments: WaitingTreatment[]): void => {
    registerUserEvent('treatment.ttt.edit.action.editTreatment', options);
    setShowTreatmentModal(true);
    setTreatmentModalEditionType('updateAndSave');
    setTreatmentsToEdit(treatments);
  };

  const onCloseModal = (): void => {
    setShowTreatmentModal(false);
    setTreatmentModalEditionType(null);
    setTreatmentsToEdit([]);
  };

  const updateTreatmentPlan = async (treatments: WaitingTreatment[]): Promise<void> => {
    const formattedTreatments = formatTreatments(treatments);
    // Since the treatments that we are modifying are deleted, the initialTreatments should not include them
    const initialTreatments = [...treatmentPlanActs];
    treatmentsToEdit.forEach((treatment) => {
      const treatmentIndex = initialTreatments.findIndex(({ id }) => id === treatment.id);
      if (treatmentIndex > -1) initialTreatments.splice(treatmentIndex, 1);
    });
    const idsToDelete = getIdsToDelete({
      formattedTreatments,
      initialTreatments: initialTreatments as WaitingTreatment[],
      editingTreatments: treatmentsToEdit,
    });
    const actsToUpdate = formattedTreatments.filter(
      ({ id, toothNumbers }) => !!id && toothNumbers.length > 1
    );
    const actsToCreate = getActsToCreate({
      formattedTreatments,
      initialTreatments,
    });
    try {
      await updateActs({
        consultationId: currentConsultation.id,
        idsToDelete,
        actsToUpdate,
        actsToCreate: actsToCreate,
      });
      setRefreshProjection(true);
      show({ text: t('planSide.acts.updated'), type: 'success' });
    } catch (error) {
      show({ text: t('planSide.acts.error'), type: 'error' });
    }
  };

  const onSubmitModal = (treatments?: WaitingTreatment[]): void => {
    if (!treatments || treatments.length === 0) return;
    if (treatmentModalEditionType === 'create' || treatmentModalEditionType === 'edit') {
      createTreatments(treatments, treatmentsToEdit);
      setShowTreatmentModal(false);
    } else if (treatmentModalEditionType === 'updateAndSave') {
      updateTreatmentPlan(treatments);
    }
    onCloseModal();
    return;
  };

  return {
    treatmentsToEdit,
    onSubmitModal,
    openAddTreatment,
    openEditTreatment,
    openEditTreatmentPlan,
    openUpdateTreatmentPlan,
    onCloseModal,
    showTreatmentModal,
    setTreatmentModalEditionType,
  };
};
