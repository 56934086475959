import { Radio } from '@allisone/react-components';
import { Control, Controller, Path } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { PATH_DPA_NO_RESELLER, PATH_DPA_RESELLER, PATH_PDF_CGU } from 'src/controller/constants';
import { useGetFilePath } from 'src/controller/file/useGetFilePath';
import { useAppStore } from 'src/controller/store';
import { isUserConcernedByDPAReseller } from 'src/controller/user/User';

import styles from './CguInput.module.scss';

type Props<T extends Record<string, any>> = {
  name: Path<T>;
  control: Control<T>;
};

export const CguInput = <T extends Record<string, unknown>>({ control, name }: Props<T>) => {
  const { t } = useTranslation('about');
  const { user } = useAppStore();
  const pathPdf = useGetFilePath(PATH_PDF_CGU);
  const userHasDPAReseller = user && isUserConcernedByDPAReseller(user.locations);
  const DPALink = useGetFilePath(userHasDPAReseller ? PATH_DPA_RESELLER : PATH_DPA_NO_RESELLER);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <div className={styles.cgu}>
          <Radio
            testId="radio-cgu"
            label=""
            onChange={() => onChange(true)}
            checked={value === true}
            id="cgu"
          />
          <label className={styles.label} htmlFor="cgu" onClick={() => onChange(true)}>
            <Trans t={t} i18nKey={'acceptCgu'}>
              <a
                onClick={(e) => e.stopPropagation()}
                href={pathPdf}
                target="_blank"
                className={styles.link}
                rel="noreferrer"
              />
              <a
                onClick={(e) => e.stopPropagation()}
                href={DPALink}
                target="_blank"
                className={styles.link}
                rel="noreferrer"
              />
            </Trans>
          </label>
        </div>
      )}
    />
  );
};
