import { useEffect, useState } from 'react';

import { ElementsCommonFieldsFragment } from 'src/communication/hasura/generated/graphql';
import { registerUserEvent } from 'src/communication/hasura/query';
import { getDetectionBoxes } from 'src/controller/basics/math';
import { buildAnnotations } from 'src/controller/consultation/buildAnnotations';
import { getUpdatedAnnotations } from 'src/controller/consultation/getUpdatedAnnotations';
import { useAppStore } from 'src/controller/store';
import { ElementFilterEnum } from 'src/controller/store/xrayViewer/types';
import { Annotation } from 'src/ui/components/XrayAnnotationTool/types';

export const useAnnotations = (
  elements: ElementsCommonFieldsFragment[] | undefined,
  cancelChanges: boolean
) => {
  const { elementsFilterMode, setElementsFilterMode, currentConsultation, currentPatient } =
    useAppStore();
  const [annotations, setAnnotations] = useState<Annotation[]>();
  const options = { consultationId: currentConsultation?.id, patientId: currentPatient?.id };

  useEffect(() => setAnnotations(buildAnnotations(elements)), [elements, cancelChanges]);

  const onCreateAnnotation = (newAnnotation: Annotation) => {
    registerUserEvent('treatment.xray.edit.action.createAnnotation', options);
    if (newAnnotation.type && elementsFilterMode !== ElementFilterEnum.ON) {
      setElementsFilterMode(ElementFilterEnum.CUSTOM);
    }
    setAnnotations((oldAnnotations) => [
      ...(oldAnnotations ?? []),
      {
        ...newAnnotation,
        detectionBoxes: getDetectionBoxes(newAnnotation.path),
        detectionScore: 1, // meaning 100%
      },
    ]);
  };

  const onUpdateSeveralAnnotations = (updatedAnnotations: Annotation[]) => {
    const newAnnotations = getUpdatedAnnotations({
      updatedAnnotations,
      annotations,
    });
    setAnnotations(newAnnotations);
  };

  const onUpdateAnnotation = (updatedAnnotation: Annotation) => {
    onUpdateSeveralAnnotations([updatedAnnotation]);
  };

  const onDeleteAnnotation = (id: number | string) => {
    registerUserEvent('treatment.xray.edit.action.deleteAnnotation', options);

    setAnnotations((oldAnnotations) =>
      (oldAnnotations ?? []).filter((annotation) => annotation.id !== id)
    );
  };

  return {
    annotations,
    onCreateAnnotation,
    onUpdateAnnotation,
    onUpdateSeveralAnnotations,
    onDeleteAnnotation,
  };
};
