import { OperationTypeSchema, WaitingTreatment } from 'src/common/types';

type Props = {
  formattedTreatments: WaitingTreatment[];
  initialTreatments: {
    id: number;
    operationType: OperationTypeSchema;
    toothNumbers: string[];
    pillarTeeth?: string[];
    groupId?: number;
  }[];
};

export const getActsToCreate = (props: Props): WaitingTreatment[] => {
  const { formattedTreatments, initialTreatments } = props;
  const actsToCreate = formattedTreatments.filter(
    (treatment) =>
      !treatment.id &&
      !initialTreatments.find(
        ({ operationType, toothNumbers }) =>
          operationType.id === treatment.operationType.id &&
          toothNumbers.length === treatment.toothNumbers.length &&
          toothNumbers.every((tooth, index) => tooth === treatment.toothNumbers[index])
      )
  );

  return actsToCreate;
};
