import { t } from 'i18next';

import { ElementTypeEnum } from 'src/common/enums';
import { Annotation } from 'src/ui/components/XrayAnnotationTool/types';

// to do: TASK-3876 add a test for this function
export const buildClientAnnotations = (
  annotations: Annotation[] | undefined
): Annotation[] | undefined => {
  if (!annotations) return;
  return annotations.map((item) => {
    const isRadiolucency = !(
      item.parentType?.nameKey === ElementTypeEnum.DECAY ||
      item.type.nameKey === ElementTypeEnum.PERIAPICAL_LESION ||
      item.type.nameKey === ElementTypeEnum.CALCULUS
    );

    if (item.editedByUser || isRadiolucency) return item;

    return {
      ...item,
      type: {
        color: 'var(--color-radiolucency)',
        id: 0,
        name: t(ElementTypeEnum.RADIOLUCENCY, { ns: 'elements' }),
        nameKey: ElementTypeEnum.RADIOLUCENCY,
      },
      parentType: {
        color: 'var(--color-radiolucency)',
        id: 0,
        name: t(ElementTypeEnum.RADIOLUCENCY, { ns: 'elements' }),
        nameKey: ElementTypeEnum.RADIOLUCENCY,
        parentId: 0,
      },
      editedByUser: false,
    };
  });
};
