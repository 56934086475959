import { createContext } from 'react';

import { CFC } from 'src/ui/components/FCWithChildren';

import { useElectronConnectProvider } from '../hooks';
import { ElectronConnect } from '../types/ElectronConnect';

export const ElectronContext = createContext<ElectronConnect | null>(null);

export const ElectronProvider: CFC = ({ children }) => (
  <ElectronContext.Provider value={useElectronConnectProvider()}>
    {children}
  </ElectronContext.Provider>
);
