import { useCallback, useMemo } from 'react';

import { App_Versions_Enum } from 'src/communication/hasura/generated/graphql';

import { useAppStore } from '..';
import { computeHasTreatmentPreferences } from './helpers/computeHasTreatmentPreferences';
import { UserGetters } from './types';

export const useUserGetters = (): UserGetters => {
  const { user, currentLocation } = useAppStore();

  const hasTreatmentPreferences = useMemo(() => {
    if (!user) return false;
    return computeHasTreatmentPreferences(user);
  }, [user]);

  const getCurrentVersion = useCallback(
    () =>
      user?.locations.find((loc) => loc.location.id === currentLocation)?.location?.version ||
      App_Versions_Enum.Commercial,
    [currentLocation, user]
  );

  return {
    hasTreatmentPreferences,
    getCurrentVersion,
  };
};
