import { createContext } from 'react';

import { CFC } from 'src/ui/components/FCWithChildren';

import { useWatcherProvider } from '../hooks';
import { ElectronWatcher } from '../hooks/watcher/useWatcherProvider';

export const WatcherContext = createContext<ElectronWatcher | null>(null);

export const WatcherProvider: CFC = ({ children }) => (
  <WatcherContext.Provider value={useWatcherProvider()}>{children}</WatcherContext.Provider>
);
