import { useEffect } from 'react';

import { useSpotimplantContext } from '../../TooltipSpotimplant/hooks';
import { listIdentifications } from '../api';
import { useGetCredentials } from './useGetCredentials';

type Hook = (props: { xrayId: string | null }) => void;

export const useFetchIdentifications: Hook = ({ xrayId }) => {
  const apiCredentials = useGetCredentials();
  const { setImplantIdentifications, setIsLoading } = useSpotimplantContext();

  useEffect(() => {
    if (!xrayId || !apiCredentials) {
      return;
    }
    const download = async () => {
      setIsLoading(true);
      try {
        const identifications = await listIdentifications({
          xrayId,
          apiCredentials,
        });
        setImplantIdentifications(identifications);
        setIsLoading(false);
      } catch (e) {
        setImplantIdentifications([]);
        setIsLoading(false);
      }
    };
    download();
  }, [xrayId]);
};
