import { Store } from '../..';
import { AllisonePlusVersion, Locale, Role } from 'src/common/enums';
import { client } from 'src/communication/apollo';
import {
  UserDocument,
  UserFieldsFragment,
  UserQuery,
} from 'src/communication/hasura/generated/graphql';
import { getAllowedRoles } from 'src/controller/Keycloak/keycloak';
import { getDefaultRole, isDentist, isHeadOf } from 'src/controller/user/User';

type Return = {
  user: UserFieldsFragment | null;
  loading: boolean;
  currentRoles: Role[];
  currentRole: Role | undefined;
  locale: Locale;
  isClinicBetaPilot: boolean;
  isAllisonePlusV2Activated: boolean;
  isEducationalSheetEnabled: boolean;
  reseller: string | undefined;
};

export const fetchUserData = async (
  userId: string,
  get: () => Store
): Promise<Return | undefined> => {
  const currentRoles = getAllowedRoles();
  const currentRole = getDefaultRole(currentRoles);

  if (!userId) return;
  const { data, loading } = await client.query<UserQuery>({
    query: UserDocument,
    variables: { userId },
  });
  const user = data.users_by_pk;

  if (!user) return;

  const locale = user.locale;
  const userLocations = user.locations;

  const isClinicBetaPilot = !!userLocations.find(({ location }) => location.isClinicBetaPilot);
  const isAllisonePlusV2Activated = !!userLocations.find(
    ({ location }) =>
      location.allisonePlusVersion === AllisonePlusVersion.V2 &&
      location.id === get().currentLocation
  );
  const isEducationalSheetEnabled =
    isHeadOf(currentRoles) ||
    (!!userLocations.some(({ location }) => location.type === 'office') && isDentist(currentRoles));

  const reseller = userLocations.find(({ location }) => location.reseller)?.location.reseller;

  return {
    user,
    loading,
    currentRoles,
    currentRole,
    locale,
    isClinicBetaPilot,
    isAllisonePlusV2Activated,
    isEducationalSheetEnabled,
    reseller: reseller ?? undefined,
  };
};
