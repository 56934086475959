import { useCallback } from 'react';

import { AnalysisElement, ConsultationQuery, ToothMl } from 'src/common/types';
import { editXrayAnalysis } from 'src/communication/api/editXrayAnalysis';
import { udpateReportsAndSuggestedTreatments } from 'src/communication/api/updateReportsAndSuggestedTreatments';
import {
  formatToSnakeCase,
  getApiAnatomy,
  getApiPeriodontics,
  getApiTeeth,
} from 'src/communication/api/utils/modelConversion';
import { buildUpdatedClinicalExams } from 'src/controller/dentistry';
import { useAppGetters, useAppStore } from 'src/controller/store';
import { DbPeriodonticLine } from 'src/ui/components/XrayAnnotationTool/types';
import { useElementTypes } from 'src/ui/pages/Patient/context';

import { useUpdateXRay } from '.';
import { useUpdateClinicalMonitoring } from '../clinical';
import { useDeleteXrayElements } from './useDeleteXrayElements';
import { useInsertXrayElements } from './useInsertXrayElements';
import { useUpdateXRayTeehtAndPerioLine } from './useUpdateXRayTeethAndPerioLine';

export function useUpdateElements() {
  const { patientXrays, setCurrentConsultation } = useAppStore();
  const { displayedXray, hasTreatmentPreferences } = useAppGetters();
  const elementTypes = useElementTypes();
  const { updateXRayTeethAndPerioLine } = useUpdateXRayTeehtAndPerioLine();
  const { insertXrayElements } = useInsertXrayElements();
  const { deleteXrayElements } = useDeleteXrayElements();
  const { updateClinicalMonitoring } = useUpdateClinicalMonitoring();
  const { updateXray } = useUpdateXRay();

  return useCallback(
    async (
      consultation: ConsultationQuery,
      elements: AnalysisElement[],
      updatedPeriodonticLine?: DbPeriodonticLine[],
      teethWithNumberEdited?: ToothMl[]
    ) => {
      if (!displayedXray) return;

      const apiPeriodontics = getApiPeriodontics(displayedXray);
      const newAnalysis = await editXrayAnalysis({
        elements,
        teeth: getApiTeeth(teethWithNumberEdited || displayedXray.teeth),
        anatomies: getApiAnatomy(displayedXray.anatomies),
        periodontics: apiPeriodontics,
        imageType: displayedXray.type,
        bridges: displayedXray.bridges,
        imageHeight: displayedXray.imageHeight || 0,
        imageWidth: displayedXray.imageWidth || 0,
      });

      //Defensive code to handle missing periodontics attribute on pano. ML does not send it
      if (!newAnalysis.periodontics) {
        newAnalysis.periodontics = apiPeriodontics;
      }
      //Prepare data to be inserted in DB
      const formattedAnalysis = formatToSnakeCase(newAnalysis, elementTypes);
      const mappedElements = formattedAnalysis?.elements.map((element) => ({
        ...element,
        editedByUser: element.editedByUser || element.detectionScore === 1,
        consultationId: consultation.id,
        xrayId: displayedXray.id,
      }));

      // delete useless elements
      const elementsToDelete = displayedXray.elements
        .filter(
          (oldElement) => !mappedElements.find((newElement) => newElement.id === oldElement.id)
        )
        .map((element) => element.id);
      await deleteXrayElements(elementsToDelete);

      // insert remaining elements (even if they are the same)
      await insertXrayElements(mappedElements);
      // update tooth numbers and periodontic lines if needed
      if (teethWithNumberEdited || updatedPeriodonticLine) {
        await updateXRayTeethAndPerioLine({
          id: displayedXray.id,
          detectedPeriodonticLines:
            updatedPeriodonticLine ?? displayedXray.detectedPeriodonticLines,
          teeth: formattedAnalysis?.teeth,
          centralTeeth: formattedAnalysis?.centralTeeth,
        });
      }

      //Update xray report after editing
      await updateXray(displayedXray.id, {
        report: formattedAnalysis.report,
        retroBoneLevels: formattedAnalysis?.retroBoneLevels,
      });
      //Update report of consultation if Xray is included
      if (displayedXray.isIncludedInReport) {
        const concernedXrays = patientXrays.filter(
          (xray) =>
            xray.isIncludedInReport &&
            xray.id !== displayedXray.id &&
            xray.consultationId === consultation.id
        );
        const xrayIds = [...concernedXrays.map(({ id }) => id), displayedXray.id];
        const updatedConsultation = await udpateReportsAndSuggestedTreatments({
          consultationId: consultation.id,
          xrayIds,
          hasTreatmentPreferences,
        });
        setCurrentConsultation(updatedConsultation);
      }

      // update the clinical monitoring
      let consultationReport = consultation.report;

      if (consultation.clinicalMonitoring?.id) {
        const { updatedDecayedTeeth, updatedMissingTeeth } = buildUpdatedClinicalExams(
          consultation?.report,
          consultationReport,
          consultation.clinicalMonitoring
        );
        await updateClinicalMonitoring(consultation.clinicalMonitoring.id, {
          decayedTeeth: updatedDecayedTeeth,
          missingTeeth: updatedMissingTeeth,
        });
      }
    },
    [elementTypes, displayedXray]
  );
}
