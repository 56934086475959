import { useCompleteActs } from './useCompleteActs';
import { useCreateActs } from './useCreateActs';
import { useDeleteActs } from './useDeleteActs';
import { useUpdateActs } from './useUpdateActs';

export function useActs() {
  const { createActs } = useCreateActs();
  const { deleteActs } = useDeleteActs();
  const { updateActs } = useUpdateActs();
  const { completeActs } = useCompleteActs();

  return {
    createActs,
    deleteActs,
    updateActs,
    completeActs,
  };
}
