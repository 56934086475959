import { IdentificationResult } from '../../TooltipSpotimplant/types/IdentificationResult';
import { ApiCredentials } from '../types';

const SPOTIMPLANT_API_URL = import.meta.env.VITE_SPOTIMPLANT_API_URL;

type Props = {
  id: string;
  apiCredentials: ApiCredentials;
};

export const getIdentificationResult = async ({
  id,
  apiCredentials,
}: Props): Promise<IdentificationResult> => {
  const url = `${SPOTIMPLANT_API_URL}/api/v1/identifications/${id}/result`;
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${apiCredentials.token}`,
      'x-hasura-role': apiCredentials.role,
      'Accept-Language': String(apiCredentials.locale),
    },
  });
  if (!response.ok) throw 'Error fetching identification result';

  const item = await response.json();
  return item;
};
