import { Dropdown } from '@allisone/react-components';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppStore } from 'src/controller/store';
import { LocationIcon } from 'src/ui/components/LocationIcon';

import styles from './CurrentLocation.module.scss';

type LocationOption = {
  id: number;
  label: string;
  prefix: JSX.Element;
  selected: boolean;
};

export const CurrentLocation: FC = () => {
  const { user, currentLocation, setCurrentLocation } = useAppStore();
  const { t } = useTranslation(['home', 'patients']);

  const locations: LocationOption[] = useMemo(
    () =>
      user?.locations.map(({ location: { id, title } }) => ({
        id,
        label: title,
        prefix: <LocationIcon locationName={title} />,
        selected: id === currentLocation,
      })) ?? [],
    [user?.locations, currentLocation]
  );

  const lastLocationId: string | null = localStorage.getItem('lastLocation');
  const lastLocation = locations.find((location) => location.id === Number(lastLocationId));

  const handleSelectLocation = (locations: LocationOption[]) => {
    const selectedLocation = locations.find((loc) => loc.selected);
    setCurrentLocation(selectedLocation?.id);
    if (selectedLocation?.id) localStorage.setItem('lastLocation', selectedLocation.id.toString());
  };

  useEffect(() => {
    if (lastLocation) {
      setCurrentLocation(lastLocation.id);
    } else if (locations.length > 0) {
      setCurrentLocation(locations[0]?.id);
    }
  }, [locations]);

  if (locations.length === 0 || !user) return null;

  if (locations.length === 1) {
    return (
      <div className={styles.singleLocation}>
        {locations[0].prefix}
        <div className={styles.title}>{locations[0].label}</div>
      </div>
    );
  }

  return (
    <div className={styles.currentLocation}>
      <Dropdown
        width="250px"
        options={locations}
        placeholder={t('home:dropdown.whichLocation')}
        icon="location"
        canSearch
        searchPlaceholder={t('patients:searchLocation')}
        noResultLabel={t('patients:noLocationFound')}
        onSelect={handleSelectLocation}
      />
    </div>
  );
};
