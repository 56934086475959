import { createContext } from 'react';

import { ElementSubtype } from 'src/common/types';
import { useElementTypesProvider } from 'src/communication/hasura/query';
import { CFC } from 'src/ui/components/FCWithChildren';
import { LoadingScreen } from 'src/ui/components/LoadingScreen';

export const ElementsTypesContext = createContext<ElementSubtype[] | null>(null);

export const ElementsTypesProvider: CFC = ({ children }) => {
  const { loading, elementTypes } = useElementTypesProvider();

  if (loading || !elementTypes) return <LoadingScreen />;
  return (
    <ElementsTypesContext.Provider value={elementTypes}>{children}</ElementsTypesContext.Provider>
  );
};
