import { FetchResult } from '@apollo/client';

import {
  InsertClinicalMonitoringMutation,
  InsertClinicalMonitoringMutationVariables,
  useInsertClinicalMonitoringMutation,
} from 'src/communication/hasura/generated/graphql';

type InsertClinicalMonitoringValues = {
  insertClinicalMonitoring(
    newClinic: InsertClinicalMonitoringMutationVariables['clinicalMonitoring']
  ): Promise<
    FetchResult<InsertClinicalMonitoringMutation, Record<string, any>, Record<string, any>>
  >;
  loading: boolean;
};

export const useInsertClinicalMonitoring = (): InsertClinicalMonitoringValues => {
  const [insertClinical, { loading }] = useInsertClinicalMonitoringMutation();

  const insertClinicalMonitoring = async (
    clinicalMonitoring: InsertClinicalMonitoringMutationVariables['clinicalMonitoring']
  ) => insertClinical({ variables: { clinicalMonitoring } });

  return { insertClinicalMonitoring, loading };
};
