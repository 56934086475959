import { t } from 'i18next';

import { ClinicForm } from '../types';

type Reasons = Omit<ClinicForm, 'medicalBackground' | 'consultationComment'>;

export const getConsultationReasons = (reasons: Reasons): any =>
  Object.entries(reasons)
    .filter(([, value]) => value)
    .map(([reason]) => t(`clinic:first.checkbox.${reason}`))
    .join(', ');
