import { StateCreator } from 'zustand';

import { Store } from '..';
import { storeResetFns } from '../resetAllStore';
import { fetchUserData } from './helpers/fetchUserData';
import { getVersions } from './helpers/getVersions';
import { UserStore, UserStoreState } from './types';
import { Locale } from 'src/common/enums';

const initialUserState: UserStoreState = {
  user: null,
  loading: false,
  currentLocation: undefined,
  currentRoles: [],
  currentRole: undefined,
  locale: Locale.En,
  isClinicBetaPilot: false,
  isAllisonePlusV2Activated: false,
  isEducationalSheetEnabled: false,
  reseller: undefined,
  locationVersions: {
    fda: false,
    ukca: false,
    ce: false,
    commercial: true,
  },
};

export const useUserStore: StateCreator<Store, [], [], UserStore> = (set, get) => {
  storeResetFns.add(() => set(initialUserState));
  return {
    ...initialUserState,
    //Setters
    setUser: (user) => set({ user }),
    setCurrentLocation: (locationId) => set({ currentLocation: locationId }),
    setIsAllisonePlusV2Activated: (isAllisonePlusV2Activated) => set({ isAllisonePlusV2Activated }),
    setLocale: (locale) => set({ locale }),
    fetchUser: async (userId: string) => {
      const userData = await fetchUserData(userId, get);
      if (!userData || !userData.user) return;
      set({
        ...userData,
        locationVersions: getVersions(userData.user.locations),
      });
    },
  };
};
