import { FC, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';

import { getPatientPartner } from 'src/ui/pages/HomePartner/useGetPatientPartner';

export const GuardAcquisition: FC = () => {
  const navigateTo = useNavigate();
  const { patientPartnerId } = useParams();

  useEffect(() => {
    getPatientPartner(patientPartnerId).then(({ patient, externalPatientId }) => {
      if (patient?.id) {
        navigateTo(`/acquisition/${patient.id}`, {
          replace: true,
          state: { patient, patientPartnerId, externalPatientId },
        });
      }
    });
  }, []);

  return <Outlet />;
};
