import { ToothMl } from 'src/common/types';
import { ToothEdit } from 'src/ui/components/Tooth/types';

export const buildTeethToSnakeCase = (teeth?: ToothEdit[]): ToothMl[] | undefined => {
  if (!teeth) return undefined;
  return teeth.map((tooth) => ({
    detected_as_missing: tooth.detectedAsMissing,
    detection_boxes: tooth.detectionBoxes,
    detection_classes: tooth.detectionClasses,
    detection_poly: tooth.detectionPoly,
    detection_scores: tooth.detectionScores,
    element_type: tooth.elementType,
    element_type_full: tooth.elementTypeFull,
    missing_tooth: tooth.missingTooth,
    edit_by_user: !!tooth.temporaryNumber || tooth.editByUser,
    corner: tooth.corner,
    x_center: tooth.xCenter,
    y_center: tooth.yCenter,
    included_tooth: tooth.includedTooth,
  }));
};
