import { useContext } from 'react';

import { PlanSideContext } from '../context';
import { PlanSideValues } from '../types';

export const usePlanSide = (): PlanSideValues => {
  const planSide = useContext(PlanSideContext);
  if (!planSide) throw new Error('usePlanSide can only be used in children of PlanSideProvider');
  return planSide;
};
