import { FetchResult } from '@apollo/client';

import {
  UpdatePatientUsersViewedMutation,
  useUpdatePatientUsersViewedMutation,
} from 'src/communication/hasura/generated/graphql';

type Return = {
  updatePatientUsersViewed: (
    patientId: number,
    usersViewed: number[]
  ) => Promise<
    FetchResult<UpdatePatientUsersViewedMutation, Record<string, any>, Record<string, any>>
  >;
};

export const useUpdatePatientUsersViewed = (): Return => {
  const [mutatePatient] = useUpdatePatientUsersViewedMutation();

  const updatePatientUsersViewed = (patientId: number, usersViewed: number[]) =>
    mutatePatient({ variables: { patientId, usersViewed } });

  return { updatePatientUsersViewed };
};
