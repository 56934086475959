import { LottieAnimation } from '@allisone/react-components';

import styles from './LoadingScreen.module.scss';

export const LoadingScreen = () => (
  <div className={styles.loaderContainer}>
    <div className={styles.loader}>
      <LottieAnimation name="spinner" />
    </div>
  </div>
);
