import { createContext } from 'react';

import { XRayValues } from './useXRay';
import { useXRayProvider } from './useXRayProvider';
import { CFC } from 'src/ui/components/FCWithChildren';

export const XRayContext = createContext<XRayValues | null>(null);

export const XRayProvider: CFC = ({ children }) => (
  <XRayContext.Provider value={useXRayProvider()}>{children}</XRayContext.Provider>
);
