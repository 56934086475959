import { useTranslation } from 'react-i18next';

import {
  EightLobeIcon,
  EightSplineIcon,
  FiveLobeIcon,
  FourLobeIcon,
  FourSplineIcon,
  HexagonIcon,
  NoAntiRotationalIcon,
  OctagonIcon,
  OnePieceAbutmentIcon,
  OnePieceBallIcon,
  PentagonIcon,
  SixLobeIcon,
  SixSplineIcon,
  SquareIcon,
  ThreeSplineIcon,
  TriangleIcon,
  TriLobeIcon,
} from '../assets/connectionShape';
import { ConnectionShape } from '../data';

const connectionShapeIconMap = {
  [ConnectionShape.HEXAGON]: <HexagonIcon />,
  [ConnectionShape.OCTAGON]: <OctagonIcon />,
  [ConnectionShape.TRI_LOBE]: <TriLobeIcon />,
  [ConnectionShape.SIX_LOBE]: <SixLobeIcon />,
  [ConnectionShape.THREE_SPLINE]: <ThreeSplineIcon />,
  [ConnectionShape.FOUR_SPLINE]: <FourSplineIcon />,
  [ConnectionShape.NO_ANTI_ROTATIONAL]: <NoAntiRotationalIcon />,
  [ConnectionShape.TRIANGLE]: <TriangleIcon />,
  [ConnectionShape.SQUARE]: <SquareIcon />,
  [ConnectionShape.PENTAGON]: <PentagonIcon />,
  [ConnectionShape.FOUR_LOBE]: <FourLobeIcon />,
  [ConnectionShape.FIVE_LOBE]: <FiveLobeIcon />,
  [ConnectionShape.EIGHT_LOBE]: <EightLobeIcon />,
  [ConnectionShape.SIX_SPLINE]: <SixSplineIcon />,
  [ConnectionShape.EIGHT_SPLINE]: <EightSplineIcon />,
  [ConnectionShape.ONE_PIECE_ABUTMENT]: <OnePieceAbutmentIcon />,
  [ConnectionShape.ONE_PIECE_BALL]: <OnePieceBallIcon />,
  [ConnectionShape.CUSTOM]: null,
};

export const useConnectionShapeOptions = () => {
  const { t } = useTranslation('spotimplant');

  return Object.keys(ConnectionShape)
    .filter((key) => isNaN(Number(key)))
    .map((key) => {
      const shape = key as keyof typeof ConnectionShape;
      return {
        value: ConnectionShape[shape],
        label: String(t(`connectionShape.${shape}`)),
        prefix: connectionShapeIconMap[ConnectionShape[shape]],
      };
    });
};
