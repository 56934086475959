import { Icon, Table, TableHeader } from '@allisone/react-components';
import { useTranslation } from 'react-i18next';

import { CFC } from 'src/ui/components/FCWithChildren';

import { useStripeGuard } from '../hooks';
import { BenificiaryContent } from './BeneficiaryContent';
import { StripeTableLine } from './StripeTableLine';

import styles from './StripeModal.module.scss';

export const StripeModalGuard: CFC = ({ children }) => {
  const { t } = useTranslation('centrePaymentModal');
  const {
    billings,
    shouldShowStripeGuard,
    skippable,
    isBeneficiary,
    onPay,
    handleClose,
    paymasters,
  } = useStripeGuard();

  const isTryingToResetPassword = ['/new-password'].includes(location.pathname);
  if (isTryingToResetPassword || !shouldShowStripeGuard) return <>{children}</>;

  return (
    <>
      {skippable && (
        <div onClick={handleClose} className={styles.cross}>
          <Icon iconName="clear" />
        </div>
      )}
      <div className={styles.container}>
        {isBeneficiary ? (
          <BenificiaryContent paymasters={paymasters} />
        ) : (
          <>
            <div className={styles.header}>
              <Icon iconName="allisoneTitle" />
            </div>
            <div className={styles.table}>
              <Table testId="stripe-guard-table">
                <TableHeader>
                  <th>{t('columns.name')}</th>
                  <th>{t('columns.formula')}</th>
                  <th>{t('columns.quantity')}</th>
                  <th>{t('columns.unitPriceExclTax')}</th>
                  <th>{t('columns.totalPriceExclTax')}</th>
                  <th>{t('columns.totalPriceInclTax')}</th>
                  <th>{t('columns.billingStartDate')}</th>
                  <th>{t('columns.amountToPay')}</th>
                  <th></th>
                </TableHeader>
                <tbody>
                  {billings.map((billing, index) => (
                    <StripeTableLine key={index} billing={billing} index={index} onPay={onPay} />
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </div>
    </>
  );
};
