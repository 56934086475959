import { Icon, SideMenu } from '@allisone/react-components';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { Countries_Enum } from 'src/communication/hasura/generated/graphql';
import { registerUserEvent } from 'src/communication/hasura/query';
import { useAppStore } from 'src/controller/store';
import { getResellerLogoUrl } from 'src/controller/user/getResellerLogoUrl';
import { isAccountant } from 'src/controller/user/User';
import { useGetImageFromCdn } from 'src/ui/components/Image';

import styles from './Menu.module.scss';

export const Menu: FC = () => {
  const navigateTo = useNavigate();
  const { t } = useTranslation('layout');
  const { pathname } = useLocation();
  const { currentRoles, reseller, user, currentLocation } = useAppStore();
  const isAccountantRole = isAccountant(currentRoles);
  const currentLocationCountry = useMemo(
    () => user?.locations?.find((loc) => loc.location.id === currentLocation)?.location?.country,
    [currentLocation, user?.locations]
  );
  const helpUrl = useMemo(() => {
    let helpCountry;
    switch (currentLocationCountry) {
      case Countries_Enum.Fr:
      case Countries_Enum.Gp:
      case Countries_Enum.Mq:
      case Countries_Enum.Re:
      case Countries_Enum.Pf:
        helpCountry = 'fr';
        break;
      case Countries_Enum.It:
        helpCountry = 'it';
        break;
      case Countries_Enum.Es:
        helpCountry = 'es';
        break;
      default:
        helpCountry = 'en';
    }
    return `https://intercom.help/allisone/${helpCountry}`;
  }, [currentLocationCountry]);

  const logoUrl = getResellerLogoUrl(reseller, true);
  const { url: resellerUrl } = useGetImageFromCdn(logoUrl);

  const entries = useMemo(() => {
    const isActive = (...paths: string[]) => paths.some((path) => pathname.startsWith(path));
    return [
      {
        icon: 'house',
        selected: pathname === '/',
        show: !isAccountantRole,
        onClick: () => handleNavigate('/'),
      },
      {
        icon: 'patients',
        selected: isActive('/patients', '/treatment'),
        show: !isAccountantRole,
        onClick: () => handleNavigate('/patients'),
      },
      {
        text: t('menu.stats'),
        icon: 'stats',
        selected: isActive('/statistics'),
        show: !isAccountantRole,
        onClick: () => handleNavigate('/statistics'),
      },
      {
        text: t('menu.marketplace'),
        icon: 'cart',
        selected: isActive('/marketplace'),
        show: !isAccountantRole,
        onClick: () => handleNavigate('/marketplace/'), // trailing slash is needed for Spotimplant iframe URL sync to work
      },
    ].filter((m) => m.show);
  }, [pathname, t, isAccountantRole]);

  const handleNavigate = useCallback(
    (path: string) => {
      registerUserEvent(`menu.${path.substring(1)}`, {});
      navigateTo(path);
    },
    [navigateTo]
  );

  const handleHelpClick = () => {
    registerUserEvent('menu.help', {});
    window.open(helpUrl, '_blank');
  };

  return (
    <nav className={styles.menu}>
      <div className={styles.upperPart}>
        <div className={styles.logo} onClick={() => handleNavigate('/')}>
          <div className={styles.allisoneLogo}>
            <Icon iconName="allisoneLogo" className={styles.icon} />
          </div>

          {resellerUrl && (
            <div className={styles.resellerLogo}>
              <Icon iconName="resellerCrossIcon" className={styles.crossIcon} />
              <img alt={`${logoUrl}`} src={resellerUrl} />
            </div>
          )}
        </div>
        <div className={styles.menuButtons}>
          <SideMenu entries={entries} />
        </div>
      </div>
      <div className={styles.helpButton} onClick={() => handleHelpClick()}>
        <Icon iconName={'help'} />
      </div>
    </nav>
  );
};
