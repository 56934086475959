import { ApolloError, FetchResult } from '@apollo/client';

import {
  UpdateClinicalMonitoringMutation,
  UpdateClinicalMonitoringMutationVariables,
  useUpdateClinicalMonitoringMutation,
} from 'src/communication/hasura/generated/graphql';

type UpdateClinicalMonitoringValues = {
  loading: boolean;
  updateClinicalMonitoring(
    id: UpdateClinicalMonitoringMutationVariables['id'],
    clinicalMonitoring: UpdateClinicalMonitoringMutationVariables['clinicalMonitoring']
  ): Promise<
    FetchResult<UpdateClinicalMonitoringMutation, Record<string, any>, Record<string, any>>
  >;
  error?: ApolloError;
};

export const useUpdateClinicalMonitoring = (): UpdateClinicalMonitoringValues => {
  const [updateClinical, { loading, error }] = useUpdateClinicalMonitoringMutation();

  const updateClinicalMonitoring = (
    id: UpdateClinicalMonitoringMutationVariables['id'],
    clinicalMonitoring: UpdateClinicalMonitoringMutationVariables['clinicalMonitoring']
  ) => updateClinical({ variables: { id, clinicalMonitoring } });

  return { updateClinicalMonitoring, loading, error };
};
