import {
  Button,
  FormLabel,
  Icon,
  InputPhoneNumber,
  LottieAnimation,
  useEventListener,
  useToaster,
} from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'react-phone-number-input';

import { axiosPost } from 'src/communication/api/utils/axiosUtils';
import { useUpdateUser } from 'src/communication/hasura/query';
import { getAllowedRoles, getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { useAppStore } from 'src/controller/store';
import { getDefaultRole } from 'src/controller/user/User';

import { LanguageDropdown } from '../SettingsPage/Preferences/Language/LanguageDropdown';
import { CguInput } from './CguInput';
import { NewUserInformationDTO, useNewUserInformation } from './useNewUserInformation';

import styles from './NewUserInformation.module.scss';

type Props = {
  onSubmit: (phone: string) => void;
};

export const NewUserInformationForm: FC<Props> = ({ onSubmit }: Props) => {
  const { t } = useTranslation(['login', 'input', 'location']);
  const { show } = useToaster();
  const { updateUser } = useUpdateUser();
  const { user } = useAppStore();
  const { handleSubmit, watch, formState, control } = useNewUserInformation();
  const phone = watch('phone');
  const token = getAuthTokens() ?? undefined;
  const currentRoles = getAllowedRoles();
  const role = getDefaultRole(currentRoles);
  const defaultCountry = (user?.locations[0]?.location.country ?? 'GB') as Country;

  const onSubmitInternal = async ({ phone }: Pick<NewUserInformationDTO, 'phone'>) => {
    if (!user) return;
    try {
      await updateUser(user.id, {
        phone,
        cgu_approved_at: new Date().toISOString(),
      });
      if (!user || !token || !role)
        throw new Error('Error while sending the welcome email, please contact the support');
      axiosPost({
        url: '/api/v2/internal/messenger/sendWelcomeEmail',
        token,
        role,
        data: {},
      });
      onSubmit(phone);
    } catch (error) {
      show({ text: error as string, type: 'error' });
    }
  };

  useEventListener('keypress', ({ key }: Pick<KeyboardEvent, 'key'>) => {
    if (key === 'Enter' && formState.isValid) {
      onSubmitInternal({ phone });
    }
  });

  return (
    <div className={styles.newUserLayout}>
      <div className={styles.column}>
        <div className={styles.languageDropdown}>{<LanguageDropdown formDesign={false} />}</div>
        <div className={styles.phoneForm}>
          <div className={styles.title}>{t('input:title')}</div>
          <div className={styles.phoneInput}>
            <FormLabel label={t('location:information.general.phone')} />
            <InputPhoneNumber
              label={t('')}
              testId="input-new-user-phone"
              name="phone"
              control={control}
              defaultCountry={defaultCountry}
            />
          </div>
          <CguInput name="cgu" control={control} />
          <Button
            testId="button-new-user-save"
            disabled={!formState.isValid}
            onClick={handleSubmit(onSubmitInternal)}
            label={t('input:save')}
            className={styles.button}
          />
        </div>
        <div>
          <Icon iconName="allisoneTitle" />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.animation}>
          <LottieAnimation name="login" />
        </div>
        <div className={styles.illustration}>
          <Icon iconName="loginEmpty" />
        </div>
      </div>
    </div>
  );
};
