import { useMemo } from 'react';

import { Xray_Type_Enum } from 'src/communication/hasura/generated/graphql';
import { isAnalyzable } from 'src/controller/dentistry/xrayTypeUtils';

import { useAppStore } from '..';
import { PatientGetters } from './types';

export const usePatientGetters = (): PatientGetters => {
  const { displayedXrayId, patientXrays, patientXrayImages } = useAppStore();

  const displayedXray = useMemo(() => {
    return patientXrays.find((xray) => xray.id === displayedXrayId);
  }, [displayedXrayId, patientXrays]);

  const displayedXrayImage = useMemo(() => {
    return patientXrayImages.find((xray) => xray.id === displayedXrayId);
  }, [displayedXrayId, patientXrayImages]);

  const hasAnalysis = useMemo(() => {
    return Boolean(displayedXray?.type && isAnalyzable(displayedXray?.type as Xray_Type_Enum));
  }, [displayedXray]);

  return {
    displayedXray,
    displayedXrayImage,
    hasAnalysis,
  };
};
