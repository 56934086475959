import { useTranslation } from 'react-i18next';

import {
  CrossIcon,
  EightPointStarIcon,
  FivePointStarIcon,
  FlatIcon,
  FourLobesIcon,
  HexagonIcon,
  SixPointStarIcon,
  SquareIcon,
} from '../assets/screwdriverShape';
import { ScrewdriverShape } from '../data';

const screwdriverShapeIconMap = {
  [ScrewdriverShape.HEXAGON]: <HexagonIcon />,
  [ScrewdriverShape.SIX_POINT_STAR]: <SixPointStarIcon />,
  [ScrewdriverShape.SQUARE]: <SquareIcon />,
  [ScrewdriverShape.FLAT]: <FlatIcon />,
  [ScrewdriverShape.FIVE_POINT_STAR]: <FivePointStarIcon />,
  [ScrewdriverShape.EIGHT_POINT_STAR]: <EightPointStarIcon />,
  [ScrewdriverShape.FOUR_LOBES]: <FourLobesIcon />,
  [ScrewdriverShape.CROSS]: <CrossIcon />,
  [ScrewdriverShape.OTHER]: null,
};

export const useScrewdriverShapeOptions = () => {
  const { t } = useTranslation('spotimplant');
  return Object.keys(ScrewdriverShape)

    .filter((key) => isNaN(Number(key)))
    .map((key) => {
      const shape = key as keyof typeof ScrewdriverShape;
      return {
        value: ScrewdriverShape[shape],
        label: String(t(`screwdriverShape.${shape}`)),
        prefix: screwdriverShapeIconMap[ScrewdriverShape[shape]],
      };
    });
};
