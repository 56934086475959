import { useLocation } from 'react-router';

import { CFC } from 'src/ui/components/FCWithChildren';

import { AppHeader } from './AppHeader';
import { Menu } from './Menu';

import styles from './ConnectedLayout.module.scss';

export const ConnectedLayout: CFC = ({ children }) => {
  const { pathname } = useLocation();

  const withoutLayout = [
    '/acquisition',
    '/treatment',
    '/partner',
    '/analysis',
    '/report',
    '/fullscreen',
    '/annotations',
    '/clinic',
  ].some((r) => pathname.includes(r));

  if (withoutLayout) return <>{children}</>;

  return (
    <div className={styles.layout}>
      <Menu />
      <div className={styles.appContent}>
        <AppHeader />
        <div key={pathname} className={styles.body}>
          {children}
        </div>
      </div>
    </div>
  );
};
