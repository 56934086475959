import { useEffect, useState } from 'react';
import { AuthContextProps } from 'react-oidc-context';

import { useAppStore } from '..';

const ALLOWED_LOCALES = [
  'en',
  'fr',
  'es',
  'pt',
  'it',
  'de',
  'jp',
  'zh',
  'se',
  'nl',
  'fi',
  'ru',
  'cs',
  'hu',
  'pl',
  'da',
  'sv',
  'no',
  'ro',
];

/** This initialize JIMO for our user tours
 * In order to use the tool these domains needs to be white listed :
 * i.usejimo.com
 * res.usejimo.com
 * stormwind.usejimo.com
 * undercity.usejimo.com
 * assets.usejimo.com
 * karabor.usejimo.com
 * karabor-undercity.usejimo.com
 * karabor-undercity-cf.usejimo.com
 */
export const useJimo = (auth: AuthContextProps) => {
  const { user } = useAppStore();
  const [userLogged, setUserLogged] = useState<number>();
  useEffect(() => {
    if (window.jimo != null) {
      return;
    }
    window.jimo = [];
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://undercity.usejimo.com/jimo-invader.js';
    window['JIMO_PROJECT_ID'] = '93caecfd-6af5-4859-884b-ece88f26f8ef';
    document.getElementsByTagName('head')[0].appendChild(s);
  }, []);

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      user &&
      user.intercomTokenId &&
      (!userLogged || userLogged !== user.id)
    ) {
      setUserLogged(user.id);
      //Preferable to set information before identify as identify reload jimo with the correct infos.
      window.jimo.push(['set', 'user:name', [user.firstname, user.lastname]]);
      window.jimo.push(['set', 'user:email', [user.email]]);
      window.jimo.push([
        'set',
        'core:language',
        [ALLOWED_LOCALES.includes(user.locale) ? user.locale : 'en'],
      ]);
      window.jimo.push(['do', 'identify', [user.intercomTokenId]]);
    }
  }, [auth, user]);
};
