import { TreatmentEnum } from 'src/common/enums';
import { WaitingTreatment } from 'src/common/types';

export const formatTreatments = (treatments: WaitingTreatment[]): WaitingTreatment[] => {
  const newTreatments: WaitingTreatment[] = [];
  const groupedTreatments = [
    TreatmentEnum.BRIDGE,
    TreatmentEnum.DENTURE,
    TreatmentEnum.ALL_ON_SURGERY,
  ];
  treatments.map((treatment) => {
    if (groupedTreatments.includes(treatment.operationType.name)) {
      newTreatments.push({
        id: treatment.id,
        toothNumbers: treatment.toothNumbers,
        operationType: treatment.operationType,
        groupId: treatment.groupId,
        pillarTeeth: treatment.pillarTeeth,
      });
    } else {
      treatment.toothNumbers.map((tooth) => {
        newTreatments.push({
          id: treatment.id,
          toothNumbers: tooth.split(' '),
          operationType: treatment.operationType,
          groupId: treatment.groupId,
          pillarTeeth: treatment.pillarTeeth,
        });
      });
    }
  });

  return newTreatments;
};
