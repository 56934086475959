import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useAppStore } from 'src/controller/store';
import { isDentist } from 'src/controller/user/User';
import { CFC } from 'src/ui/components/FCWithChildren';
import { Route } from 'src/ui/router';

import { CurrentLocation } from './CurrentLocation';
import { HeaderMenu } from './HeaderMenu';

import styles from '../ConnectedLayout.module.scss';

export const AppHeader: CFC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation([
    'home',
    'location',
    'settings',
    'treatmentPreferences',
    'about',
    'educationalSheet',
    'invoices',
    'spotimplant',
  ]);
  const { user, currentRoles } = useAppStore();

  const isWelcomeVisible = [Route.HOME, Route.ROOT].some((route) => pathname === route);
  const welcomeTitle = t(isDentist(currentRoles) ? 'home:welcome.dentist' : 'home:welcome.other', {
    lastname: user?.lastname,
    firstname: user?.firstname,
  });
  return (
    <header className={styles.appHeader}>
      <div className={styles.leftPart}>
        {isWelcomeVisible && <div className={styles.title}>{welcomeTitle}</div>}
        {pathname === Route.LOCATIONS && (
          <>
            <div className={styles.title}>{t('location:centers.title')}</div>
            <div className={styles.subtitle}>{t('location:centers.subtitle')}</div>
          </>
        )}
        {pathname === Route.ACCOUNT && <div className={styles.title}>{t('settings:title')}</div>}
        {pathname === Route.PREFERENCES && (
          <div className={styles.title}>{t('treatmentPreferences:myTreatments')}</div>
        )}
        {pathname === Route.ABOUT && <div className={styles.title}>{t('about:title')}</div>}
        {pathname === Route.EDUCATIONAL_SHEETS && (
          <>
            <div className={styles.title}>{t('educationalSheet:educationalSheets')}</div>
            <div className={styles.subtitle}>{t('educationalSheet:subtitle')}</div>
          </>
        )}
        {pathname === Route.STATISTICS && <div className={styles.title}>{t('stats:title')}</div>}
        {pathname === Route.INVOICES && (
          <>
            <div className={styles.title}>{t('invoices:title')}</div>
            <div className={styles.subtitle}>{t('invoices:subTitle')}</div>
          </>
        )}
        {pathname.includes('/marketplace/') && (
          <div className={styles.title}>{t('spotimplant:marketplace')}</div>
        )}
      </div>

      <div className={styles.headerOptions}>
        <CurrentLocation />
        <HeaderMenu />
      </div>
    </header>
  );
};
