import { Report } from 'src/common/types';
import { CreateConsultationMutationVariables } from 'src/communication/hasura/generated/graphql';
import { getMissingAndDecayedTeethFromReport } from 'src/controller/consultation/getMissingAndDecayedTeethFromReport';

export const getInitialClinicalMonitoring = (
  consultationId: number,
  report?: Report | null
): CreateConsultationMutationVariables['newConsultation']['clinicalMonitoring'] => {
  const { decayedTeeth, missingTeeth } = getMissingAndDecayedTeethFromReport(report);
  return {
    data: {
      consultationComment: '',
      medicalBackground: '',
      decayedTeeth,
      damagedTeeth: [],
      looseTeeth: [],
      inflammationTeeth: [],
      missingTeeth,
      occlusionTeeth: [],
      consultationId,
    },
  };
};
