import { createContext } from 'react';

import { ElementGroupMode } from 'src/common/enums';
import { AnalysisElement, ToothMl as ToothSchema } from 'src/common/types';
import { CFC } from 'src/ui/components/FCWithChildren';
import { DbPeriodonticLine, EditionMode } from 'src/ui/components/XrayAnnotationTool/types';

import { useTreatmentProvider } from './useTreatmentProvider';

export type SubmitEmailData = {
  treatmentPlanPDF: string;
  emails: string[] | string;
  observations: string;
};

export type PatientModal = {
  locationId: number;
  patient: {
    id: number;
    lastname: string;
    firstname: string;
    date: Date;
    userId: number;
    birthdate?: Date;
  };
  isOpen: boolean;
  onClose: () => void;
  consultationId?: number;
};

export type FullScreenMode = 'analyzed' | 'original';

type NavigateTo = {
  fullscreen?(): void;
  treatment?(): void;
  backward?(): void;
  annotations?(): void;
  toNewTreatment?(treatmentId: string): void;
};

export type Treatment = {
  onBack(): void;
  setShowPatientModal(showPatientModal: boolean): void;
  fullScreenVisible: boolean;
  setFullScreenVisible(e: boolean): void;
  isOpen: boolean;
  submitXRayEdition(newElements: AnalysisElement[], teethWithNumberEdit?: ToothSchema[]): void;
  onSubmit(data: SubmitEmailData, sendingInProgress: { isLoading: boolean }): Promise<void>;
  patientModal: PatientModal;
  fullScreenMode: FullScreenMode;
  setFullScreenMode(fullscreenMode: FullScreenMode): void;
  editionMode: EditionMode;
  setEditionMode(editionMode: EditionMode): void;
  xrayContainsChanges: boolean;
  setXrayContainsChanges(xrayContainsChanges: boolean): void;
  onCloseModal(): void;
  elementGroupMode: ElementGroupMode;
  handleSetElementGroupMode(elementGroupMode: ElementGroupMode): Promise<void>;
  navigateTo: NavigateTo;
  setUpdatedPeriodonticLine: (updatedPeriodonticLine: DbPeriodonticLine[] | undefined) => void;
  updatedPeriodonticLine?: DbPeriodonticLine[];
};

export const TreatmentContext = createContext<Treatment | null>(null);

export const TreatmentProvider: CFC = ({ children }) => (
  <TreatmentContext.Provider value={useTreatmentProvider()}>{children}</TreatmentContext.Provider>
);
