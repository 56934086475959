import { LottieAnimation, TooltipProvider } from '@allisone/react-components';
import * as Sentry from '@sentry/react';
import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Role } from 'src/common/enums';
import { Error } from 'src/ui/pages/Error';

import { PaymentStatusModal } from '../components/modals/PaymentStatusModal';
import { AuthRoute } from './AuthRoute';
import { GuardAcquisition } from './GuardAcquisition';
import { Layout } from './layouts';
import {
  About,
  Account,
  Acquisition,
  Analysis,
  Consultation,
  EducationalSheets,
  Home,
  HomePartner,
  Invoices,
  Location,
  Locations,
  Marketplace,
  NotFound,
  PatientPage,
  PatientsList,
  Stats,
  TreatmentPreferences,
  XrayRedirect,
} from './lazyRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { Route as RouteEnum } from './routes';
import { ToasterManager } from './ToasterManager';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Router: FC = () => (
  <BrowserRouter>
    <ToasterManager>
      <TooltipProvider>
        <Sentry.ErrorBoundary
          fallback={({ error, resetError }) => {
            if (error.name === 'ChunkLoadError') {
              resetError();
              return (
                <Layout>
                  <div style={{ width: '4rem', height: '4rem' }}>
                    <LottieAnimation name="spinner" />
                  </div>
                </Layout>
              );
            }
            return (
              <Layout>
                <Error error={error} resetError={resetError} />
              </Layout>
            );
          }}
        >
          <SentryRoutes>
            <Route element={<AuthRoute />}>
              <Route
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      Role.DENTIST,
                      Role.DIRECTOR,
                      Role.HEAD_OF,
                      Role.ASSISTANT,
                      Role.SECRETARY,
                    ]}
                  />
                }
              >
                <Route path={RouteEnum.ROOT} element={<Home />} />
              </Route>
              <Route path={RouteEnum.PATIENTS}>
                <Route index element={<PatientsList />} />
              </Route>
              <Route path={RouteEnum.STATISTICS} element={<Stats />} />
              <Route path={RouteEnum.PARTNER} element={<HomePartner />} />
              <Route path={RouteEnum.PMS_PATIENT} element={<GuardAcquisition />} />
              <Route path={RouteEnum.ACQUISITION} element={<Acquisition />} />
              <Route path={RouteEnum.PATIENT} element={<PatientPage />}>
                <Route path={RouteEnum.TREATMENT} element={<Consultation />} />
                <Route path={RouteEnum.TREATMENT_VALIDATE} element={<Consultation />} />
                <Route path={RouteEnum.REPORT} element={<Consultation />} />
                <Route path={RouteEnum.CLINIC} element={<Consultation />} />
                <Route path={RouteEnum.ANALYSIS} element={<Analysis />} />
                <Route path={RouteEnum.FULLSCREEN} element={<Analysis />} />
                <Route path={RouteEnum.ANNOTATIONS} element={<Analysis />} />
              </Route>
              <Route path={RouteEnum.XRAY} element={<XrayRedirect />} />
              <Route path={RouteEnum.STATISTICS} element={<Stats />} />
              <Route
                element={
                  <ProtectedRoute allowedRoles={[Role.HEAD_OF, Role.DIRECTOR, Role.ACCOUNTANT]} />
                }
              >
                <Route path={RouteEnum.LOCATIONS}>
                  <Route index element={<Locations />} />
                  <Route path={RouteEnum.ID} element={<Location />} />
                </Route>
              </Route>
              <Route path={RouteEnum.PREFERENCES} element={<TreatmentPreferences />} />
              <Route element={<ProtectedRoute allowedRoles={[Role.HEAD_OF, Role.DENTIST]} />}>
                <Route path={RouteEnum.EDUCATIONAL_SHEETS} element={<EducationalSheets />} />
              </Route>
              <Route path={RouteEnum.INVOICES} element={<Invoices />} />
              <Route path={RouteEnum.HOME} element={<Navigate to={RouteEnum.ROOT} replace />} />
              <Route path={RouteEnum.ACCOUNT} element={<Account />} />
              <Route path={RouteEnum.ABOUT} element={<About />} />
              <Route
                path={RouteEnum.PAYMENT_FAILED}
                element={<PaymentStatusModal type="failed" />}
              />
              <Route
                path={RouteEnum.PAYMENT_SUCCESS}
                element={<PaymentStatusModal type="success" />}
              />
              <Route path={RouteEnum.MARKETPLACE} element={<Marketplace />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
        </Sentry.ErrorBoundary>
      </TooltipProvider>
    </ToasterManager>
  </BrowserRouter>
);
