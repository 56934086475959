import { User } from 'oidc-client-ts';

import { Locale, Role } from 'src/common/enums';
import { AuthTokens } from 'src/common/types';

const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL as string;
const KEYCLOAK_REALM = (import.meta.env.VITE_KEYCLOAK_REALM as string) || 'allisone';
const KEYCLOAK_CLIENT_ID = (import.meta.env.VITE_KEYCLOAK_CLIENT_ID as string) || 'dental-webapp';

export const getUser = (): User | null => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}:${KEYCLOAK_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const getAuthTokens = (): AuthTokens | null => {
  const user = getUser();
  if (!user) return null;

  return {
    access_token: user?.access_token || '',
    refresh_token: user?.refresh_token || '',
    token_type: user?.token_type || '',
  };
};

export const getAllowedRoles = (): Role[] => {
  const user = getUser();
  const claims = user?.profile?.['https://hasura.io/jwt/claims'] as any;
  return claims?.['x-hasura-allowed-roles'] as Role[];
};

enum KeycloakLocale {
  Ca = 'ca',
  De = 'de',
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Pl = 'pl',
  PtBr = 'pt-BR',
  Sv = 'sv',
}

const keycloakLocaleMap = {
  [Locale.Ca]: KeycloakLocale.Ca,
  [Locale.De]: KeycloakLocale.De,
  [Locale.En]: KeycloakLocale.En,
  [Locale.Es]: KeycloakLocale.Es,
  [Locale.Fr]: KeycloakLocale.Fr,
  [Locale.It]: KeycloakLocale.It,
  [Locale.Pt]: KeycloakLocale.PtBr,
  [Locale.Sv]: KeycloakLocale.Sv,
  [Locale.Pl]: KeycloakLocale.Pl,
};

export const convertToKeycloakLocale = (locale?: Locale): KeycloakLocale => {
  if (!locale || !Object.keys(keycloakLocaleMap).includes(locale)) {
    return KeycloakLocale.En;
  }
  return keycloakLocaleMap[locale];
};
