import {
  UpdateXRayRetroRulerMutationVariables,
  useUpdateXRayRetroRulerMutation,
} from 'src/communication/hasura/generated/graphql';

type UpdateRetroRulerSegments = {
  updateRetroRulerSegments(items: UpdateXRayRetroRulerMutationVariables): Promise<void>;
};

export const useUpdateRetroRulerSegments = (): UpdateRetroRulerSegments => {
  const [update] = useUpdateXRayRetroRulerMutation();

  const updateRetroRulerSegments = async (variables: UpdateXRayRetroRulerMutationVariables) => {
    await update({
      variables,
      optimisticResponse: {
        updateXray: {
          id: variables.id,
          retroRulerSegments: variables.retroRulerSegments,
          __typename: 'xrays',
        },
      },
    });
  };

  return { updateRetroRulerSegments };
};
